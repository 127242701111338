.compare-plans {
  position: relative;
  z-index: 5;
  background-color: var(--bg-color);
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 64px 80px;
    @media screen and (max-width: $lg) {
      padding: 80px 24px 60px;
    }
  }
  .title {
    text-align: center;
    margin-bottom: 32px;
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  .compare-table {
    width: 100%;
  }
  .table-head {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .items-head {
    width: calc(33.3% + 1px);
    height: 40px;
    padding: 8px;
    background-color: $purple-points;
    border-radius: 16px 0 0 0;
    .light & {
      background-color: $orange-points;
    }
    @media screen and (max-width: $lg) {
      width: 50%;
      height: auto;
    }
  }
  .items-based-head {
    width: 33.3%;
    background-color: $blue-grey;
    border-radius: 0 16px 0 0;
    @media screen and (max-width: $lg) {
      width: 50%;
    }
    .light & {
      background-color: $orange-grey;
    }
  }
  .head-text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    @media screen and (max-width: $lg) {
      font-size: 14px;
    }
  }
  .table-body {
    background-color: var(--nav-btn-color);
    border-radius: 16px 0 16px 16px;
    @media screen and (max-width: $lg) {
      border-radius: 0 0 16px 16px;
    }
  }
  .table-row {
    display: flex;
    align-items: stretch;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    color: var(--opacity-08);
    border-bottom: 1px solid var(--opacity-02);
    @media screen and (max-width: $lg) {
      flex-wrap: wrap;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  .table-title-row {
    font-weight: 500;
    font-size: 28px;
    line-height: 1.4;
    color: var(--font-color);
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
  .table-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
    padding: 24px;
    text-align: center;
    border-right: 1px solid var(--opacity-02);
    &:nth-child(1) {
      width: 33.4%;
    }
    @media screen and (max-width: 1400px) {
      padding: 16px;
    }
    @media screen and (max-width: $lg) {
      width: 50%;
      padding: 8px;
      padding-top: 46px;
      font-size: 14px;
      &::before {
        content: attr(data-title);
        position: absolute;
        top: 8px;
        font-size: 14px;
        color: var(--opacity-06);
      }
    }
    &:last-child {
      border-right: 0;
    }
    @media screen and (max-width: $lg) {
      &:first-child {
        border-right: 0;
        border-bottom: 1px solid var(--opacity-02);
      }
    }
  }
  .table-item.first-item {
    display: flex;
    justify-content: flex-start;
    width: 33.4%;
    padding-left: 56px;
    font-size: 22px;
    text-align: left;
    @media screen and (max-width: $lg) {
      justify-content: center;
      width: 100%;
      font-size: 16px;
      text-align: center;
      padding: 8px;
      &::before {
        display: none;
      }
    }
  }
  .table-title-row .table-item {
    padding: 24px 56px;
    font-size: 28px;
    @media screen and (max-width: 1400px) {
      padding: 24px 24px;
    }
    @media screen and (max-width: $lg) {
      padding: 8px;
      &.first-item {
        padding-left: 8px;
      }
    }
  }
  .item-true {
    min-height: 18px;
    background-image: url('../../public/svg/list.svg');
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width: $lg) {
      min-height: 80px;
      background-position: center bottom 16px;
    }
  }
  .item-false {
    min-height: 18px;
    background-image: url('../../public/svg/list-false.svg');
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width: $lg) {
      min-height: 80px;
      background-position: center bottom 16px;
    }
    .light & {
      background-image: url('../../public/svg/list-false-light.svg');
    }
  }
}

