.partners-page .customer {
  position: sticky;
  padding-bottom: 120px;
}

.customer {
  position: relative;
  z-index: 5;
  transform: translateZ(0);
  margin: 0 auto;
  overflow-x: hidden;
  background-color: $dark;
  padding-bottom: 64px;
  @media screen and (max-width: $lg) {
    padding-bottom: 120px;
  }
  .light & {
    background-color: $light;
  }
  .subtitle {
    margin-left: 20px;
    @media screen and (max-width: $lg) {
      margin-bottom: 12px;
    }
    &::before {
      background-color: $green-points;
      .light & {
        background-color: $orange-points;
      }
    }
  }
  .title {
    max-width: 700px;
    @media screen and (max-width: $lg) {
      margin-bottom: -12px;
    }
  }
  .slider {
    width: 1648px;
  }
  .customer-slider-wrapper {
    width: 1648px;
    max-width: 100%;
    overflow: hidden;
    // 656px is half of page container width
    margin-left: calc(50vw - 656px);
    @media screen and (max-width: 1440px) {
      margin-left: 64px;
    }
    @media screen and (max-width: $lg) {
      width: 528px;
      margin: 0 auto;
    }
    @media screen and (max-width: $sm) {
      width: 327px;
    }
  }
  .customer-slider-nav {
    position: absolute;
    // half screen width + half container width - pagination width
    left: calc(50% + 524px);
    top: 166px;
    @media screen and (max-width: 1440px) {
      right: 64px;
      left: auto;
    }
    @media screen and (max-width: $lg) {
      top: auto;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

