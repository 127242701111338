.leaders {
  position: relative;
  z-index: 5;
  margin: 0 auto;
  overflow-x: hidden;
  transform: translateZ(0);
  background-color: var(--bg-color);
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 100px;
    @media screen and (max-width: $lg) {
      align-items: flex-start;
      padding-bottom: 80px;
    }
  }
  .subtitle {
    margin-left: 20px;
    @media screen and (max-width: $lg) {
      margin-bottom: 12px;
    }
    &::before {
      background-color: $green-points;
    }
  }
  .title {
    margin-bottom: 16px;
    text-align: center;
    @media screen and (max-width: $lg) {
      text-align: left;
    }
  }
  .text {
    max-width: 860px;
    margin-bottom: 48px;
    font-weight: 400;
    text-align: center;
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
      font-size: 16px;
      text-align: left;
    }
  }
  .slider {
    width: 1312px;
    @media screen and (max-width: $lg) {
      width: 808px;
    }
  }
  .slider-wrapper {
    width: 1312px;
    margin-bottom: 32px;
    overflow: hidden;
    @media screen and (max-width: 1360px) {
      width: calc(100vw - 48px);
    }
  }
  .slider-nav {
    display: none;
    @media screen and (max-width: 1360px) {
      display: block;
      margin: 0 auto;
    }
  }
}

