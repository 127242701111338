.home-hero {
  position: sticky;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    height: auto;
  }
  & .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 70px 64px 120px;
    min-height: 680px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      justify-content: flex-start;
      padding: 50px 24px 80px;
      min-height: 774px;
    }
    @media screen and (max-width: 576px) {
      min-height: 690px;
    }
    @media screen and (max-width: 435px) {
      min-height: 730px;
    }
  }
  .title {
    max-width: 740px;
    margin-bottom: 28px;
    font-size: 62px;
    line-height: 1.3;
    @media screen and (max-width: $xxl) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media screen and (max-width: $xl) {
      font-size: 40px;
      line-height: 1.3;
    }
    @media screen and (max-width: $lg) {
      padding-top: 16px;
      margin-bottom: 22px;
      max-width: 420px;
      font-size: 32px;
    }
  }
  .design {
    width: 528px;
    height: 375px;
    margin-top: -10px;
    @media screen and (max-width: 1400px) {
      width: 327px;
      height: 231px;
    }
    @media screen and (max-width: $lg) {
      width: 528px;
      height: 375px;
      margin: 0 auto;
    }
    @media screen and (max-width: 576px) {
      width: 327px;
      height: 231px;
    }
  }
  .arrow-right {
    display: inline-block;
    width: 52px;
    height: 32px;
    margin: 0 15px;
    background-image: url('../../public/svg/arrow-right.svg');
    background-size: contain;
    @media screen and (max-width: $lg) {
      width: 29px;
      height: 18px;
    }
    .light & {
      background-image: url('../../public/svg/arrow-right-light.svg');
    }
  }
  .highlight {
    display: inline-block;
    color: $blue;
    .light & {
      color: $orange;
    }
  }
  .text {
    max-width: 488px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.6;
    @media screen and (max-width: $lg) {
      font-size: 18px;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 350px;
    @media screen and (max-width: $lg) {
      max-width: 306px;
      margin-bottom: 60px;
    }
  }
  .bg-line {
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
}

