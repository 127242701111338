.get-touch {
  position: relative;
  z-index: 5;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  min-height: calc(100vh - 212px);
  @media screen and (max-width: $lg) {
    min-height: calc(100vh - 445px);
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 104px 130px;
    @media screen and (max-width: $lg) {
      padding: 80px 24px 30px;
    }
  }
  .title {
    text-align: center;
    margin-bottom: 64px;
    @media screen and (max-width: $lg) {
      margin-bottom: 40px;
    }
  }
  .get-touch-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 72px;
    @media screen and (max-width: 1255px) {
      justify-content: center;
    }
    @media screen and (max-width: $lg) {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

