@font-face {
  font-family: 'Thicccboi';
  src: url('../public/fonts/thicccboi/THICCCBOI-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Thicccboi';
  src: url('../public/fonts/thicccboi/THICCCBOI-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Thicccboi';
  src: url('../public/fonts/thicccboi/THICCCBOI-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Thicccboi';
  src: url('../public/fonts/thicccboi/THICCCBOI-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Thicccboi';
  src: url('../public/fonts/thicccboi/THICCCBOI-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Thicccboi';
  src: url('../public/fonts/thicccboi/THICCCBOI-Black.woff2') format('woff2');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../public/fonts/opensans/OpenSans-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../public/fonts/opensans/OpenSans-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../public/fonts/opensans/OpenSans-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

