.leaders-item {
  .leader-img {
    position: relative;
    width: 416px;
    height: 470px;
    margin: 0 auto;
    background-size: cover;
    background-position: bottom center;
    @media screen and (max-width: $lg) {
      width: 200px;
      height: 270px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 290px;
      background-image: url('../../../public/img/leader-slide-shadow.png');
      background-size: auto;
      background-position: center top;
      @media screen and (max-width: 1200px) {
        height: 187px;
      }
      .light & {
        background-image: url('../../../public/img/leader-slide-shadow-light.png');
      }
    }
  }
  .slide-content {
    width: 416px;
    min-height: 270px;
    padding: 24px;
    border-radius: 16px 16px 0 0;
    background: linear-gradient(180deg, #062d54 0%, #031830 100%);
    .light & {
      background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
    }
    @media screen and (max-width: $lg) {
      width: 248px;
      min-height: 210px;
      padding: 16px;
    }
  }
  .leader-name {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--font-color);
    text-align: left;
    @media screen and (max-width: $lg) {
      font-size: 22px;
    }
  }
  .leader-job {
    color: var(--btn-color);
    margin-bottom: 24px;
    text-align: left;
    @media screen and (max-width: $lg) {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
    }
  }
  .leader-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: var(--opacity-08);
    @media screen and (max-width: $lg) {
      font-size: 14px;
    }
  }
}

