.home-hero-anim {
  position: relative;
  width: 528px;
  height: 375px;
  background-image: url('../../../public/img/grafic.png');
  background-size: contain;
  overflow-x: hidden;
  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }
  & * {
    pointer-events: none;
  }
  .light & {
    background-image: url('../../../public/img/grafic-light.png');
  }
  .lines {
    position: relative;
    width: 178px;
    height: 76px;
    left: 59px;
    top: 96px;
  }
  .line {
    position: relative;
    height: 5px;
    border-radius: 2px;
  }
  .line1 {
    top: 21px;
    left: 0;
    width: 130px;
    background-color: #6749e7;
  }
  .value {
    position: absolute;
    font-size: 12px;
    line-height: 1;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.4);
    background-color: $icons;
    .light & {
      color: rgba(3, 24, 48, 0.6) !important;
      background-color: $white;
    }
  }
  .line2-value1,
  .line2-value2 {
    top: 44px;
  }
  .line2 {
    top: 65px;
    left: 0;
    width: 44px;
    background-color: $blue-points;
  }
  .items {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 180px;
    height: 82px;
    left: 39px;
    top: 196px;
  }
  .item:nth-child(odd) {
    margin-right: -1px;
    z-index: 2;
    .light & {
      background-color: #f25656;
    }
  }
  .item:nth-child(2n) {
    margin-right: 6px;
  }
  .item {
    position: relative;
    width: 5px;
    border-radius: 2px;
    bottom: 0;
  }
  .item1 {
    height: 12px;
    background-color: $blue-points;
  }
  .item2 {
    height: 33px;
    background-color: #6749e7;
  }
  .item3 {
    height: 22px;
    background-color: $blue-points;
  }
  .item4 {
    height: 61px;
    background-color: #6749e7;
  }
  .item5 {
    height: 15px;
    background-color: $blue-points;
  }
  .item6 {
    height: 35px;
    background-color: #6749e7;
  }
  .item7 {
    height: 18px;
    background-color: $blue-points;
  }
  .item8 {
    height: 43px;
    background-color: #6749e7;
  }
  .item9 {
    height: 20px;
    background-color: $blue-points;
  }
  .item10 {
    height: 48px;
    background-color: #6749e7;
  }
  .item11 {
    height: 7px;
    background-color: $blue-points;
  }
  .item12 {
    height: 50px;
    background-color: #6749e7;
  }
  .item13 {
    height: 18px;
    background-color: $blue-points;
  }
  .item14 {
    height: 43px;
    background-color: #6749e7;
  }
  .item15 {
    height: 12px;
    background-color: $blue-points;
  }
  .item16 {
    height: 70px;
    background-color: #6749e7;
  }
  .item17 {
    height: 13px;
    background-color: $blue-points;
  }
  .item18 {
    height: 33px;
    background-color: #6749e7;
  }
  .item19 {
    height: 4px;
    background-color: $blue-points;
  }
  .item20 {
    height: 18px;
    background-color: #6749e7;
  }
  .item21 {
    height: 9px;
    background-color: $blue-points;
  }
  .item22 {
    height: 5px;
    background-color: #6749e7;
  }
  .item23 {
    height: 21px;
    background-color: $blue-points;
  }
  .item24 {
    height: 18px;
    background-color: #6749e7;
  }
  .chart {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 214px;
    height: 110px;
    left: 300px;
    top: -30px;
  }
  .chart1 {
    position: relative;
    bottom: 0;
    width: 75px;
    height: 38px;
    background-image: url('../../../public/svg/chart.svg');
    background-position: left bottom;
  }
  .chart2 {
    position: relative;
    bottom: 0;
    width: 57px;
    height: 38px;
    background-image: url('../../../public/svg/chart.svg');
    background-position: left -75px bottom;
  }
  .chart3 {
    position: relative;
    bottom: 0;
    width: 61px;
    height: 38px;
    background-image: url('../../../public/svg/chart.svg');
    background-position: left -132px bottom;
  }
  .chart4 {
    position: relative;
    bottom: 0;
    width: 28px;
    height: 38px;
    background-image: url('../../../public/svg/chart.svg');
    background-position: left -193px bottom;
  }
  .point {
    position: absolute;
    z-index: 2;
    left: 427px;
    top: 175px;
    width: 62px;
    height: 62px;
    background-image: url('../../../public/svg/point-green.svg');
    background-size: contain;
  }
  .union {
    position: absolute;
    z-index: 2;
    left: 425px;
    top: 145px;
    width: 66px;
    height: 32px;
    background-image: url('../../../public/img/union.png');
    background-size: contain;
    font-size: 12px;
    line-height: 1;
    color: rgba(3, 24, 48, 0.6);
    text-align: center;
    line-height: 28px;
    .light & {
      background-image: url('../../../public/img/union-light.png');
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

.home-hero-anim.anim {
  .union1 {
    animation: union 2s infinite alternate;
  }
  .union2 {
    animation: union 2s infinite alternate, text-1 2s infinite alternate;
  }
  @keyframes union {
    0% {
      top: 145px;
    }
    50% {
      top: 100px;
    }
    100% {
      top: 145px;
    }
  }
  .line1-value2,
  .line2-value2 {
    animation: text-1 1s infinite alternate-reverse;
  }
  @keyframes text-1 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .point {
    animation: point 2s infinite alternate, color-5 2s infinite alternate;
  }
  @keyframes point {
    0% {
      top: 175px;
    }
    50% {
      top: 130px;
    }
    100% {
      top: 175px;
    }
  }
  .chart * {
    animation: chart-1 2s infinite alternate, color-5 2s infinite alternate;
  }
  @keyframes chart-1 {
    0% {
      transform: scaleY(1);
      bottom: 0;
    }
    50% {
      transform: scaleY(2.2);
      bottom: 24px;
    }
    100% {
      transform: scaleY(1);
      bottom: 0;
    }
  }
  .line1 {
    animation: line-1 2s infinite alternate, color-1 1s infinite alternate;
  }
  .line2 {
    background-color: $blue-points;
    animation: line-2 2s infinite alternate, color-4 1s infinite alternate-reverse;
  }
  .item1 {
    animation: item-1 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item2 {
    animation: item-2 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item3 {
    animation: item-3 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item4 {
    animation: item-4 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item5 {
    animation: item-5 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item6 {
    animation: item-6 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item7 {
    animation: item-7 2s infinite alternate, color-2 1s infinite alternate;
  }
  .item8 {
    animation: item-8 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item9 {
    animation: item-9 2s infinite alternate, color-2 1s infinite alternate;
  }
  .item10 {
    animation: item-10 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item11 {
    animation: item-11 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item12 {
    animation: item-12 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item13 {
    animation: item-13 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item14 {
    animation: item-14 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item15 {
    animation: item-15 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item16 {
    animation: item-16 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item17 {
    animation: item-17 2s infinite alternate, color-2 1s infinite alternate;
  }
  .item18 {
    animation: item-18 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item19 {
    animation: item-19 2s infinite alternate, color-2 1s infinite alternate;
  }
  .item20 {
    animation: item-20 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item21 {
    animation: item-21 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item22 {
    animation: item-22 2s infinite alternate, color-3 1s infinite alternate;
  }
  .item23 {
    animation: item-23 2s infinite alternate, color-2 1s infinite alternate-reverse;
  }
  .item24 {
    animation: item-24 2s infinite alternate, color-3 1s infinite alternate;
  }
  @keyframes line-1 {
    0% {
      width: 130px;
    }
    50% {
      width: 14px;
    }
    100% {
      width: 130px;
    }
  }
  @keyframes line-2 {
    0% {
      width: 38px;
    }
    50% {
      width: 140px;
    }
    100% {
      width: 38px;
    }
  }
  @keyframes color-1 {
    0% {
      background-color: #6749e7;
    }
    40% {
      background-color: #6749e7;
    }
    60% {
      background-color: $blue-points;
    }
    100% {
      background-color: $blue-points;
    }
  }
  @keyframes color-4 {
    0% {
      background-color: #6749e7;
    }
    40% {
      background-color: #6749e7;
    }
    60% {
      background-color: #f3a64b;
    }
    100% {
      background-color: #f3a64b;
    }
  }
  @keyframes item-1 {
    0% {
      height: 12px;
    }
    50% {
      height: 45px;
    }
    100% {
      height: 12px;
    }
  }
  @keyframes item-2 {
    0% {
      height: 33px;
    }
    50% {
      height: 12px;
    }
    100% {
      height: 33px;
    }
  }
  @keyframes item-3 {
    0% {
      height: 22px;
    }
    50% {
      height: 78px;
    }
    100% {
      height: 22px;
    }
  }
  @keyframes item-4 {
    0% {
      height: 61px;
    }
    50% {
      height: 27px;
    }
    100% {
      height: 61px;
    }
  }
  @keyframes item-5 {
    0% {
      height: 15px;
    }
    50% {
      height: 50px;
    }
    100% {
      height: 15px;
    }
  }
  @keyframes item-6 {
    0% {
      height: 35px;
    }
    50% {
      height: 15px;
    }
    100% {
      height: 35px;
    }
  }
  @keyframes item-7 {
    0% {
      height: 18px;
    }
    50% {
      height: 43px;
    }
    100% {
      height: 18px;
    }
  }
  @keyframes item-8 {
    0% {
      height: 43px;
    }
    50% {
      height: 18px;
    }
    100% {
      height: 43px;
    }
  }
  @keyframes item-9 {
    0% {
      height: 20px;
    }
    50% {
      height: 72px;
    }
    100% {
      height: 20px;
    }
  }
  @keyframes item-10 {
    0% {
      height: 48px;
    }
    50% {
      height: 22px;
    }
    100% {
      height: 48px;
    }
  }
  @keyframes item-11 {
    0% {
      height: 7px;
    }
    50% {
      height: 24px;
    }
    100% {
      height: 7px;
    }
  }
  @keyframes item-12 {
    0% {
      height: 50px;
    }
    50% {
      height: 22px;
    }
    100% {
      height: 50px;
    }
  }
  @keyframes item-13 {
    0% {
      height: 18px;
    }
    50% {
      height: 60px;
    }
    100% {
      height: 18px;
    }
  }
  @keyframes item-14 {
    0% {
      height: 43px;
    }
    50% {
      height: 19px;
    }
    100% {
      height: 43px;
    }
  }
  @keyframes item-15 {
    0% {
      height: 12px;
    }
    50% {
      height: 44px;
    }
    100% {
      height: 12px;
    }
  }
  @keyframes item-16 {
    0% {
      height: 70px;
    }
    50% {
      height: 31px;
    }
    100% {
      height: 70px;
    }
  }
  @keyframes item-17 {
    0% {
      height: 13px;
    }
    50% {
      height: 44px;
    }
    100% {
      height: 13px;
    }
  }
  @keyframes item-18 {
    0% {
      height: 33px;
    }
    50% {
      height: 15px;
    }
    100% {
      height: 33px;
    }
  }
  @keyframes item-19 {
    0% {
      height: 4px;
    }
    50% {
      height: 16px;
    }
    100% {
      height: 4px;
    }
  }
  @keyframes item-20 {
    0% {
      height: 18px;
    }
    50% {
      height: 8px;
    }
    100% {
      height: 18px;
    }
  }
  @keyframes item-21 {
    0% {
      height: 9px;
    }
    50% {
      height: 32px;
    }
    100% {
      height: 9px;
    }
  }
  @keyframes item-22 {
    0% {
      height: 5px;
    }
    50% {
      height: 24px;
    }
    100% {
      height: 5px;
    }
  }
  @keyframes item-23 {
    0% {
      height: 21px;
    }
    50% {
      height: 75px;
    }
    100% {
      height: 21px;
    }
  }
  @keyframes item-24 {
    0% {
      height: 18px;
    }
    50% {
      height: 9px;
    }
    100% {
      height: 18px;
    }
  }
  @keyframes color-5 {
    0% {
      filter: hue-rotate(0);
    }
    40% {
      filter: hue-rotate(0);
    }
    60% {
      filter: hue-rotate(190deg);
    }
    100% {
      filter: hue-rotate(190deg);
    }
  }
}

.home-hero-anim {
  @media screen and (max-width: 1400px) and (min-width: 756px), screen and (max-width: 576px) {
    overflow: hidden;
    width: 327px;
    height: 231px;
    .lines {
      width: 110px;
      height: 47px;
      left: 36px;
      top: 60px;
    }
    .line {
      height: 3px;
    }
    .line1 {
      top: 13px;
      width: 80px;
    }
    .line2-value1,
    .line2-value2 {
      top: 27px;
    }
    .line2 {
      top: 40px;
      width: 27px;
    }
    @keyframes line-1 {
      0% {
        width: 80px;
      }
      50% {
        width: 9px;
      }
      100% {
        width: 80px;
      }
    }
    @keyframes line-2 {
      0% {
        width: 24px;
      }
      50% {
        width: 87px;
      }
      100% {
        width: 24px;
      }
    }
    .items {
      width: 112px;
      height: 50px;
      left: 24px;
      top: 122px;
    }
    .item:nth-child(2n) {
      margin-right: 4px;
    }
    .item {
      width: 3px;
      border-radius: 1px;
      transform: scaleY(0.6);
      transform-origin: bottom;
    }
    .chart {
      width: 133px;
      height: 48px;
      left: 186px;
      top: -17px;
      overflow: hidden;
    }
    .chart1 {
      width: 47px;
      height: 32px;
    }
    .chart2 {
      width: 35px;
      height: 32px;
      background-position: left -47px bottom;
    }
    .chart3 {
      width: 38px;
      height: 32px;
      background-position: left -82px bottom;
    }
    .chart4 {
      width: 17px;
      height: 32px;
      background-position: left -120px bottom;
    }
    @keyframes chart-1 {
      0% {
        transform: scaleY(1);
        bottom: 0;
      }
      50% {
        transform: scaleY(1.5);
        bottom: 5px;
      }
      100% {
        transform: scaleY(1);
        bottom: 0;
      }
    }
    .union {
      display: none;
    }
    .point {
      left: 265px;
      top: 75px;
      width: 52px;
      height: 52px;
    }
    @keyframes point {
      0% {
        top: 75px;
      }
      50% {
        top: 65px;
      }
      100% {
        top: 75px;
      }
    }
  }
}

