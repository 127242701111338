.integrations-honey {
  position: sticky;
  z-index: 5;
  background-color: var(--bg-color);
  transform: translateZ(0);
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 120px;
    @media screen and (max-width: $lg) {
      padding: 0 24px 80px;
    }
  }
  .subtitle {
    display: flex;
    margin: 0px auto 32px;
    @media screen and (max-width: $lg) {
      margin: 0px auto 16px;
    }
    &::before {
      background-color: $orange-points;
    }
  }
  .title {
    max-width: 560px;
    margin-bottom: 64px;
    text-align: center;
    @media screen and (max-width: $lg) {
      margin-bottom: 40px;
    }
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    column-gap: 120px;
    width: 1160px;
    margin: 0 auto 52px;
    @media screen and (max-width: $xxl) {
      column-gap: 72px;
      width: 696px;
    }
    @media screen and (max-width: $lg) {
      column-gap: 76px;
      width: 327px;
    }
  }
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background-image: url('../../public/img/honey-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    .light & {
      background-image: url('../../public/img/honey-bg-light.png');
    }
    &:nth-child(1),
    &:nth-child(8) {
      margin-left: 160px;
      @media screen and (max-width: $xxl) {
        margin-left: 96px;
      }
    }
    &:nth-child(1),
    &:nth-child(4) {
      @media screen and (max-width: $lg) {
        margin-left: 100px;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
      margin-top: -104px;
      @media screen and (max-width: $xxl) {
        margin-top: -64px;
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      @media screen and (max-width: $lg) {
        margin-top: -68px;
      }
    }
    @media screen and (max-width: $xxl) {
      width: 120px;
      height: 120px;
    }
    @media screen and (max-width: $lg) {
      width: 126;
      height: 126;
    }
  }
  .image-container {
    width: 55px;
    height: 54px;
    margin-bottom: 8px;
    @media screen and (max-width: $xxl) {
      width: 35px;
      height: 34px;
      margin-bottom: 5px;
    }
  }
  .company {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    @media screen and (max-width: $xxl) {
      font-size: 14px;
    }
  }
  .image {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

