.pricing-item {
  width: 100%;
  padding: 56px 56px 60px 72px;
  @media screen and (max-width: $xl) {
    padding: 32px 0px 60px 24px;
  }
  .card-title {
    margin-bottom: 16px;
  }
  .card-subtitle {
    min-height: 14px;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    color: var(--font-color);
  }
  .price {
    // margin-bottom: 25px;
    // font-size: 36px;
    // font-weight: 600;
    // line-height: 40px;
    color: var(--font-color);
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
    & span {
      display: inline-block;
      padding-left: 8px;
      font-size: 18px;
      font-weight: 400;
      line-height: 40px;
      color: var(--opacity-06);
    }
  }
  .line {
    height: 1px;
    width: 100%;
    margin-bottom: 40px;
    background-color: var(--opacity-02);
  }
  .btn-wrapper {
    padding-bottom: 40px;
  }
  .list-item {
    line-height: 1.6;
    @media screen and (max-width: $lg) {
      font-size: 14px;
      padding-bottom: 12px;
    }
  }
}

.free,
.business {
  border-right: 1px solid var(--opacity-02);
  @media screen and (max-width: $lg) {
    border-right: 0;
  }
}

.free {
  @media screen and (max-width: 576px) {
    border-bottom: 1px solid var(--opacity-02);
  }
}

.home-page .pricing-item {
  .btn-wrapper,
  .line,
  .list {
    display: none;
  }
}
.pricing-page .pricing-item {
  padding-bottom: 0px;
  @media screen and (max-width: 576px) {
    padding-bottom: 60px;
    &:last-child {
      padding-bottom: 80px;
    }
  }
  .link-wrapper {
    display: none;
  }
  @media screen and (max-width: $lg) {
    .line {
      display: none;
    }
  }
}

