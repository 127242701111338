.security {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  height: 752px;
  min-height: 100vh;
  // 656px is half of page container width
  padding: 150px calc(50vw - 656px) 80px;
  @media screen and (max-width: 1440px) {
    padding: 150px 64px 80px;
  }
  @media screen and (max-width: $lg) {
    height: auto;
    min-height: auto;
    padding: 60px 24px 80px;
  }
  .pagination {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // half screen width + half container width - pagination width
    left: calc(50% + 632px);
    width: 24px;
    height: 352px;
    @media screen and (max-width: 1440px) {
      left: auto;
      right: 64px;
    }
    @media screen and (max-width: $lg) {
      display: none;
    }
    .slide-num {
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }
    .track {
      height: 252px;
      width: 2px;
      margin: 32px auto 42px;
      background-color: rgba(255, 255, 255, 0.2);
      .light & {
        background-color: rgba(3, 24, 48, 0.2);
      }
    }
    .scale {
      width: 2px;
      transition: 0.5s;
      background-color: $blue-points;
      .light & {
        background-color: $orange-points;
      }
    }
  }
  .slider {
    width: 300vw;
    height: 100vh;
    min-height: 632px;
    cursor: ew-resize;
    @media screen and (max-width: $lg) {
      height: auto;
      min-height: auto;
      cursor: auto;
    }
  }
  .slider-wrapper {
    width: 100vw;
    overflow: hidden;
    margin: 0 auto 64px;
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
  .security-items {
    display: none;
    @media screen and (max-width: $lg) {
      display: block;
      width: 100%;
    }
  }
}

