.security {
  display: none;
  transform: translateZ(0);
  @media screen and (max-width: $lg) {
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    padding: 34px 24px 80px;
  }
  .security-items {
    display: none;
    @media screen and (max-width: $lg) {
      display: block;
      width: 100%;
    }
  }
}

