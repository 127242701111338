.social {
  display: flex;
  height: 32px;
  &-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-item {
    list-style: none;
  }
  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--nav-btn-color);
    transition: 0.3s;
    &:hover {
      background-color: var(--btn-color);
    }
    & svg path {
      fill: var(--font-color);
    }
    .light &:hover svg path {
      fill: $white;
    }
  }
  .social-item {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

