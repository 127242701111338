.nav-btn-prev {
  position: relative;
  .svg {
    transform: scaleX(1);
    & path {
      transition: 0.3s ease-in-out;
      fill: var(--font-color);
    }
  }
  &:hover .svg path {
    fill: var(--bg-color);
  }
}

