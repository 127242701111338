.slider-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 24px);
  height: 100vh;
  min-height: 632px;
  @media screen and (max-width: $lg) {
    display: none;
  }
  .image-slide {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: calc(50% - 20px);
    max-width: 598px;
    height: 512px;
    border-radius: 24px;
    overflow: hidden;
    background-image: linear-gradient(to top, #55d0f7, #57b8ff);
    @media screen and (max-width: $xxl) {
      height: 440px;
      height: 377px;
    }
    .light & {
      background-image: linear-gradient(to top, #f3a64b, #fe7518);
    }
  }
  .img {
    width: 466px;
    height: 407px;
    background-size: contain;
    background-position: center bottom;
    @media screen and (max-width: $xxl) {
      width: 400px;
      height: 350px;
    }
  }
  .content-slider {
    width: calc(50% - 20px);
    max-width: 598px;
    height: 512px;
    padding-left: 64px;
    padding-top: 64px;
    overflow: hidden;
    @media screen and (max-width: 1280px) {
      padding: 20px;
    }
    @media screen and (max-width: $xxl) {
      height: 440px;
    }
  }
  .content-slide {
    min-height: 359px;
    .subtitle {
      margin-left: 20px;
      margin-bottom: 24px;
      @media screen and (max-width: $lg) {
        display: none;
      }
      &.blue {
        &::before {
          background-color: $blue-points;
        }
      }
      &.purple {
        &::before {
          background-color: $purple-points;
        }
      }
      &.orange {
        &::before {
          background-color: $orange-points;
        }
      }
    }
    .slide-title {
      max-width: 480px;
      text-align: left;
      font-size: 56px;
      font-weight: 500;
      margin-bottom: 32px;
      @media screen and (max-width: $xxl) {
        font-size: 48px;
      }
      @media screen and (max-width: $xl) {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
    .slide-text {
      max-width: 480px;
      margin-bottom: 48px;
      @media screen and (max-width: $xxl) {
        margin-bottom: 32px;
      }
      @media screen and (max-width: $xl) {
        font-size: 16px;
        margin-bottom: 16px;
      }
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

