.form {
  width: calc(50% - 20px);
  max-width: 600px;
  padding: 48px;
  background-color: $icons;
  border-radius: 24px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 24px;
  }
  .light & {
    //background: $white;
    background: #d7dadd;
    box-shadow: 0px 4px 32px rgba(196, 196, 196, 0.14);
  }
  .form-title {
    margin-bottom: 16px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.1;
    color: $white;
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
      font-size: 22px;
      font-weight: 500;
    }
    .light & {
      color: $dark;
    }
  }
  .form-subtitle {
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    @media screen and (max-width: $lg) {
      margin-bottom: 26px;
    }
  }
  .contact-form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 32px;
    @media screen and (max-width: $lg) {
      gap: 24px;
    }
  }
  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    &.half-input {
      width: calc(50% - 8px);
      @media screen and (max-width: $lg) {
        width: 100%;
      }
    }
    label {
      display: block;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 1;
      color: rgba(255, 255, 255, 0.7);
      @media screen and (max-width: $lg) {
        font-size: 12px;
      }
      .light & {
        color: rgba(3, 24, 48, 0.7);
      }
    }
    input {
      display: block;
      width: 100%;
      height: 48px;
      padding: 11px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: rgba(255, 255, 255, 0.5);
      background-color: $dark;
      border: 1px solid transparent;
      outline: 0;
      border-radius: 8px;
      transition: 0.3s;
      @media screen and (max-width: $lg) {
        font-size: 14px;
      }
      .light & {
        background-color: #eff0f5;
        color: rgba(3, 24, 48, 0.5);
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
        .light & {
          color: rgba(3, 24, 48, 0.5);
        }
        &:hover {
          color: rgba(255, 255, 255, 0.7);
          .light & {
            color: rgba(3, 24, 48, 0.7);
          }
        }
      }
      &:hover {
        color: rgba(255, 255, 255, 0.7);
        border-color: rgba(255, 255, 255, 0.4);
        .light & {
          color: rgba(3, 24, 48, 0.7);
          border-color: rgba(3, 24, 48, 0.4);
        }
      }
      &:active,
      &:focus {
        color: rgba(255, 255, 255, 0.8);
        border-color: rgba(255, 255, 255, 1);
        .light & {
          color: rgba(3, 24, 48, 1);
          border-color: rgba(3, 24, 48, 0.8);
        }
      }
      &.valid {
        border-color: #27ae60;
      }
      &.invalid {
        border-color: #eb5757;
      }
    }
  }
  input:-webkit-autofill {
    box-shadow: inset 0 0 0 50px $dark;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
    .light & {
      box-shadow: inset 0 0 0 50px #eff0f5;
      -webkit-text-fill-color: rgba(3, 24, 48, 1);
    }
  }
  input:not(:placeholder-shown) {
    color: rgba(255, 255, 255, 0.8);
    .light & {
      -webkit-text-fill-color: rgba(3, 24, 48, 1);
    }
    & ~ label {
      color: rgba(255, 255, 255, 1);
    }
    .light & ~ label {
      color: rgba(3, 24, 48, 1);
    }
  }
  .form-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 6px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 36px;
    }
  }
  .checkbox-input {
    position: relative;
  }
  .chb {
    visibility: hidden;
  }
  .chb + label {
    position: relative;
    padding-left: 16px;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    @media screen and (max-width: $lg) {
      font-size: 14px;
    }
    .light & {
      color: rgba(3, 24, 48, 0.5);
    }
  }
  .chb-3 + label::before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    left: -10px;
    top: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    transition: all 0.1s ease-in, border-color 0.05s ease-in;
    .light & {
      border: 1px solid rgba(3, 24, 48, 0.2);
    }
  }
  .chb-3.invalid + label::before {
    border-color: #eb5757;
  }
  .chb-3:checked + label::before {
    transform: rotate(40deg);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #27ae60;
    border-right-color: #27ae60;
    border-width: 3px;
    top: 0px;
    left: -8px;
    width: 7px;
    height: 12px;
  }
  .input-invalid {
    display: none;
    position: absolute;
    bottom: -20px;
    padding-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #eb5757;
  }

  input.invalid ~ .input-invalid {
    display: block;
  }
}

