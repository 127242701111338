// Colors
$dark: #031830; //rgba(3, 24, 48, .2);
$light: #f4f4f4;
$dark-grey: #192743;
$light-grey: #ebebeb;
$dark-blue: #22466d;
$hover-btn: #004993;
$icons: #192743;
$blue: #0180ff;
$orange: #fe7518;
$white: #ffffff;

$blue-points: #56ccf2;
$green-points: #45cf6d;
$purple-points: #a386f4;
$orange-points: #f3a64b;

$btn-dark-orange: #ff4f00;
$btn-orange: #ff5e15;
$btn-dark-blue: #004993;
$btn-blue: #0064c9;

$blue-grey: #22466d;
$orange-grey: #ffe2bf;

// Breakpoints
$sm: 480px;
$lg: 768px;
$xl: 980px;
$xxl: 1200px;

