.double {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  .container {
    display: flex;
    justify-content: space-between;
    padding: 120px 64px 0px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 80px 24px 0px;
    }
    &.reverse {
      flex-direction: row-reverse;
      @media screen and (max-width: $lg) {
        flex-direction: column;
      }
    }
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: calc(50% - 20px);
    max-width: 598px;
    height: 512px;
    border-radius: 20px;
    overflow: hidden;
    @media screen and (max-width: $xxl) {
      height: 440px;
      align-items: center;
    }
    @media screen and (max-width: $lg) {
      align-items: flex-end;
      width: 327px;
      height: 293px;
      margin-bottom: 22px;
    }
  }
  .img {
    width: 552px;
    height: 464px;
    background-size: contain;
    background-position: center bottom;
    border-radius: 20px 20px 0 0;
    @media screen and (max-width: $xxl) {
      width: 400px;
      height: 350px;
    }
    @media screen and (max-width: $lg) {
      width: 268px;
      height: 266px;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(50% - 20px);
    max-width: 598px;
    @media screen and (max-width: 1280px) {
      padding: 20px;
    }
    @media screen and (max-width: $lg) {
      width: 327px;
      padding: 20px 0 0;
    }
  }
  .content {
    .subtitle {
      margin-left: 20px;
      margin-bottom: 24px;
      @media screen and (max-width: $lg) {
        margin-bottom: 16px;
      }
    }
    .title {
      text-align: left;
      font-size: 56px;
      font-weight: 500;
      margin-bottom: 48px;
      @media screen and (max-width: $xxl) {
        font-size: 48px;
      }
      @media screen and (max-width: $xl) {
        font-size: 32px;
        margin-bottom: 24px;
      }
      @media screen and (max-width: $lg) {
        font-size: 27px;
        margin-bottom: 16px;
      }
    }
    .list-item {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.6;
      @media screen and (max-width: $lg) {
        font-size: 14px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.liveview {
  .container {
    padding-bottom: 120px;
  }
}

.user_activity {
  padding-bottom: 80px;
}

.playbooks,
.cloudSIEM,
.continuousMonitoring,
.threatHunting,
.infrastructureManagement,
.automation,
.liveview,
.network,
.user_activity .distributed_tracing {
  .container {
    @media screen and (max-width: $lg) {
      padding-bottom: 80px;
    }
  }
}

