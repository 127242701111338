.service-provider-item {
  .card-title {
    margin-bottom: 24px;
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
  }
  .text {
    @media screen and (max-width: $lg) {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
    }
  }
}

