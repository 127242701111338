.goal {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  .container {
    display: flex;
    justify-content: space-between;
    padding: 90px 64px 120px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      justify-content: flex-start;
      padding: 80px 24px;
    }
  }
  .subtitle {
    margin-left: 20px;
    @media screen and (max-width: $lg) {
      margin-bottom: 12px;
    }
  }
  .content {
    max-width: 600px;
    padding-top: 32px;
    @media screen and (max-width: 1200px) {
      margin-right: 40px;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 64px;
      margin-right: 0;
    }
  }
  .title {
    margin-bottom: 40px;
    @media screen and (max-width: $lg) {
      margin-bottom: 20px;
    }
  }
  .text {
    margin-bottom: 24px;
    @media screen and (max-width: $lg) {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
    }
  }
  .design {
    display: flex;
    flex-shrink: 0;
    margin-right: 20px;
    @media screen and (max-width: 1200px) {
      margin: 0;
      padding-top: 100px;
    }
    @media screen and (max-width: $lg) {
      margin: 0 auto;
      padding-top: 0;
    }
  }
}

