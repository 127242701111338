.dropdown {
  position: relative;
  width: fit-content;
  .dropdownHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p.nav-link {
      padding-right: 2px;
      &::after {
        display: none;
      }
    }
    .dropdown-arrow {
      background-position: top 4px center;
      opacity: 0.6;
    }
    &:hover .nav-link,
    &:hover .dropdown-arrow {
      opacity: 1;
    }
  }
  .dropdownBody {
    position: fixed;
    top: 71px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - 120px);
    max-width: 1320px;
    padding: 32px 0px;
    background: linear-gradient(180deg, #062d54 0%, rgba(3, 24, 48, 0) 136%), #192743;
    box-shadow: 0px 0px 80px rgba(3, 24, 48, 0.8);
    border-radius: 8px;
    display: none;
    @media screen and (max-width: $lg) {
      background: var(--bg-color);
    }
    &.open {
      display: block;
    }
    .light & {
      background: linear-gradient(180deg, #ffffff -4%, #f4f4f4 95%);
      box-shadow: 0px 0px 120px rgba(3, 24, 48, 0.1);
      @media screen and (max-width: $lg) {
        background: var(--bg-color);
      }
    }
  }
  .dropdown-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dropdown-column {
    position: relative;
    display: flex;
    justify-content: center;
    width: 25%;
    @media screen and (max-width: $xxl) {
      width: 50%;
      margin-bottom: 20px;
    }
    @media screen and (max-width: $lg) {
      width: 100%;
      justify-content: flex-start;
      padding-left: 24px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--opacity-02);
    }
    &:last-child::after {
      display: none;
    }
    &:nth-child(2) .dropdown-link-subtitle {
      &::before {
        background-color: $green-points;
      }
      &:hover {
        color: $green-points;
      }
    }
    &:nth-child(3) .dropdown-link-subtitle {
      &::before {
        background-color: $purple-points;
      }
      &:hover {
        color: $purple-points;
      }
    }
    &:nth-child(4) .dropdown-link-subtitle {
      &::before {
        background-color: $orange-points;
      }
      &:hover {
        color: $orange-points;
      }
    }
  }
  .dropdown-inner-column {
    width: 246px;
  }
  .dropdown-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 258px;
    height: 48px;
    padding: 0;
    padding-left: 12px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    transition: 0.3s;
    &:hover .dropdown-icon {
      opacity: 1;
      filter: invert(51%) sepia(70%) saturate(6525%) hue-rotate(200deg) brightness(104%) contrast(107%);
    }
    &:hover .dropdown-link-name {
      color: var(--font-color);
    }
    .light &:hover .dropdown-icon {
      opacity: 1;
      filter: invert(73%) sepia(54%) saturate(6692%) hue-rotate(349deg) brightness(101%) contrast(99%);
    }
  }
  .dropdown-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    opacity: 0.6;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(6deg) brightness(120%) contrast(116%);
    .light & {
      filter: invert(9%) sepia(14%) saturate(4870%) hue-rotate(184deg) brightness(92%) contrast(103%);
    }
  }
  .dropdown-icon-none {
    display: none;
  }
  .dropdown-link-name {
    display: inline-block;
    padding-left: 16px;
    transition: 0.3s;
    color: var(--opacity-06);
  }
  .dropdown-link-subtitle {
    position: relative;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-left: 32px;
    &::before {
      content: '';
      position: absolute;
      left: 12px;
      top: 6px;
      width: 8px;
      height: 8px;
      border: 1px solid transparent;
      border-radius: 2px;
      background-color: $blue-points;
    }
    &:hover {
      opacity: 1;
      color: $blue-points;
    }
    @media screen and (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 0;
      &::before {
        top: 5px;
      }
    }
  }
  .dropdown-link-active {
    border-radius: 8px;
    background-color: var(--btn-color);
    color: $white;
    & .dropdown-icon {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(6deg) brightness(120%) contrast(116%);
    }
  }
}

.open .dropdown {
  width: 100%;
  .dropdownHeader {
    position: relative;
    & .dropdown-arrow {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 24px;
      background-size: 24px 24px;
    }
    .nav-link::after {
      display: block;
    }
  }
  .dropdownBody {
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: auto;
    padding: 0;
    padding-top: 20px;
    top: -2px;
    .nav-link {
      font-size: 16px;
      padding: 11px 24px;
      color: rgba(255, 255, 255, 0.8);
      &::after {
        display: none;
      }
    }
  }
  .navItem {
    padding: 0;
  }
  .nav-link.active {
    background-color: $blue;
    &::before {
      display: none;
    }
  }
}

.dropdownOpen {
  .dropdownHeader {
    .nav-link::after {
      display: none;
    }
  }
  .nav-link::after {
    display: none;
  }
  .dropdown-link-subtitle {
    &::before {
      top: 6px;
    }
  }
}

.light .open .dropdown {
  .dropdownBody {
    .dropdownBody::after {
      background-color: rgba(3, 24, 48, 0.1);
    }
    .nav-link {
      color: rgba(3, 24, 48, 0.6);
      &.active {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .nav-link {
    color: rgba(3, 24, 48, 0.6);
  }
}

