.pricing {
  position: relative;
  z-index: 5;
  background-color: var(--bg-color);
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 24px 120px;
    @media screen and (max-width: $lg) {
      align-items: flex-start;
      padding-bottom: 80px;
    }
  }
  .subtitle {
    display: inline-flex;
    margin: 0px auto 24px;
    @media screen and (max-width: $lg) {
      margin-bottom: 12px;
      margin-left: 20px;
    }
    &::before {
      background-color: $purple-points;
    }
  }
  .title {
    max-width: 800px;
    text-align: center;
    margin-bottom: 32px;
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  .pricing-items {
    width: 100%;
    max-width: 1089px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px 16px 0 0;
    background: var(--honeycomb);
    background-size: auto;
    @media screen and (max-width: 1144px) {
      max-width: 981px;
    }
    @media screen and (max-width: 1030px) {
      width: 765px;
    }
    @media screen and (max-width: $lg) {
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }
  }
  .pricing-items-cloud {
    position: relative;
    width: 50%;
    display: flex;
    padding-top: 40px;
    @media screen and (max-width: $lg) {
      width: 100%;
      margin: 0 auto;
    }
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }
  .pricing-items-based {
    position: relative;
    width: 50%;
    display: flex;
    padding-top: 40px;
    @media screen and (max-width: $lg) {
      background: var(--honeycomb);
      background-size: auto;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      border-radius: 16px 16px 0 0;
    }
  }
  .items-head {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: $purple-points;
    border-radius: 16px 0 0 0;
    .light & {
      background-color: $orange-points;
    }
    @media screen and (max-width: $lg) {
      border-radius: 16px 16px 0 0;
    }
  }
  .items-based-head {
    background-color: $blue-grey;
    border-radius: 0 16px 0 0;
    .light & {
      background-color: $orange-grey;
    }
    @media screen and (max-width: $lg) {
      border-radius: 16px 16px 0 0;
    }
  }
  .head-text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
  }
}

.pricing-page .pricing {
  position: relative;
  z-index: 4;
  min-height: 100vh;
  padding-bottom: 0;
  @media screen and (max-width: $xl) {
    min-height: auto;
  }
  .container {
    padding-bottom: 0px;
    padding-top: 50px;
  }
  .title {
    font-size: 62px;
    @media screen and (max-width: $xxl) {
      font-size: 54px;
      line-height: 1.3;
    }
    @media screen and (max-width: $xl) {
      font-size: 44px;
    }
    @media screen and (max-width: $lg) {
      width: 100%;
      padding-top: 0;
      font-size: 32px;
    }
  }
  .highlight {
    color: $blue;
    .light & {
      color: $orange;
    }
  }
  .text {
    max-width: 644px;
    margin-bottom: 64px;
    font-size: 22px;
    line-height: 1.6;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    @media screen and (max-width: $lg) {
      margin-bottom: 40px;
      font-size: 18px;
    }
    .light & {
      color: rgba(3, 24, 48, 0.8);
    }
  }
}

