.security-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 1200px;
  height: 100vh;
  min-height: 632px;
  @media screen and (max-width: 1440px) {
    width: calc(100vw - 132px - 64px);
    padding-left: 64px;
  }
  @media screen and (max-width: $lg) {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto 16px;
    width: calc(100vw - 48px);
    padding-left: 0;
    height: auto;
    min-height: auto;
  }
  &:last-child {
    @media screen and (max-width: $lg) {
      margin-bottom: 0;
    }
  }
  .image-slider {
    width: 598px;
    height: 512px;
    border-radius: 24px;
    overflow: hidden;
    @media screen and (max-width: $lg) {
      width: 327px;
      height: 293px;
      margin-bottom: 32px;
    }
  }
  .content-slider {
    display: flex;
    align-items: center;
    width: 42.5%;
    height: 512px;
    padding-left: 22px;
    @media screen and (max-width: 1400px) {
      padding-left: 0;
    }
    @media screen and (max-width: $lg) {
      align-items: flex-start;
      width: 327px;
      height: 222px;
    }
  }
  .image-slide {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 512px;
    background-image: linear-gradient(to top, #55d0f7, #57b8ff);
    @media screen and (max-width: $lg) {
      width: 327px;
      height: 293px;
    }
    .light & {
      background-image: linear-gradient(to top, #f3a64b, #fe7518);
    }
    & .image-container {
      width: 98%;
      max-width: 566px;
      height: 447px;
      @media screen and (max-width: $lg) {
        width: 295px;
        height: 258px;
      }
    }
  }
  .content-slide {
    min-height: 359px;
    .subtitle {
      margin-left: 20px;
      margin-bottom: 24px;
      @media screen and (max-width: $lg) {
        display: none;
      }
      .light &::before {
        background-color: $purple-points;
      }
    }
    .title {
      margin-bottom: 32px;
      @media screen and (max-width: $lg) {
        max-width: 240px;
        margin-bottom: 16px;
        font-size: 27px;
        line-height: 1.1;
      }
    }
    .text {
      margin-bottom: 64px;
      @media screen and (max-width: $lg) {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }
  .card-header {
    width: 100%;
    text-transform: uppercase;
    padding: 24px 0 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: none;
    @media screen and (max-width: $lg) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 327px;
    }
    .subtitle {
      margin-left: 24px;
      margin-bottom: 12px;
    }
  }
  &:nth-child(2) {
    .card-header {
      .subtitle {
        &::before {
          background-color: $purple-points;
        }
      }
    }
  }
  &:nth-child(3) {
    .card-header {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .subtitle {
        &::before {
          background-color: $orange-points;
        }
      }
    }
  }
  .toggle-btn {
    width: 24px;
    height: 24px;
    background-image: url('../../../public/svg/plus.svg');
    background-size: auto;
    display: none;
    @media screen and (max-width: $lg) {
      display: flex;
    }
    .light & {
      background-image: url('../../../public/svg/plus-light.svg');
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
}

.security-item.open {
  @media screen and (max-width: $lg) {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto 32px;
      width: calc(100vw - 48px);
    }
  }
  .toggle-btn {
    @media screen and (max-width: $lg) {
      background-image: url('../../../public/svg/minus.svg');
      .light & {
        background-image: url('../../../public/svg/minus-light.svg');
      }
    }
  }
  .subtitle {
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  &:nth-child(3) {
    .card-header {
      padding-bottom: 0px;
      border-bottom: 0;
    }
  }
}

