.technology {
  position: sticky;
  z-index: 5;
  min-height: 100vh;
  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    min-height: auto;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 64px 240px;
    @media screen and (max-width: $lg) {
      padding: 80px 24px 120px;
    }
  }
  .subtitle {
    margin-left: 20px;
    &::before {
      background-color: $green-points;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
  }
  .title {
    max-width: 600px;
    margin-bottom: 64px;
    text-align: center;
    @media screen and (max-width: $lg) {
      margin-bottom: 28px;
    }
  }
  .wrapper {
    margin-bottom: 12px;
    overflow-x: hidden;
  }
  .technology-items {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2px);
  }
  .technology-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 25%;
    height: 210px;
    padding-left: 56px;
    padding-top: 32px;
    border-bottom: 1px solid var(--opacity-01);
    border-right: 1px solid var(--opacity-01);
    overflow: hidden;
    cursor: pointer;
    &:nth-last-child(-n + 4) {
      border-bottom: 0;
    }
    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9) {
      padding-left: 0;
    }
    @media screen and (max-width: $xxl) {
      padding-left: 20px;
    }
    @media screen and (max-width: $lg) {
      width: 50%;
      height: 150px;
      border-bottom: 1px solid var(--opacity-01);
      &:nth-last-child(4),
      &:nth-last-child(3) {
        border-bottom: 1px solid var(--opacity-01);
      }
      &:nth-child(odd) {
        padding-left: 0;
        padding-top: 20px;
      }
    }
  }
  .technology-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
    border-radius: 50%;
    background-color: var(--nav-btn-color);
    @media screen and (max-width: $lg) {
      width: 40px;
      height: 40px;
      margin-bottom: 16px;
    }
  }
  .technology-ico {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(70deg) brightness(108%) contrast(108%);
    @media screen and (max-width: $lg) {
      width: 16px;
      height: 16px;
    }
    .light & {
      filter: invert(10%) sepia(9%) saturate(5745%) hue-rotate(179deg) brightness(94%) contrast(105%);
    }
  }
  .text {
    max-width: 240px;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.6;
    @media screen and (max-width: $lg) {
      font-size: 16px;
    }
  }
  .arrow-link {
    display: none;
    @media screen and (max-width: $lg) {
      display: block;
    }
  }
  .technology-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 32px 26px 0 92px;
    background-color: var(--btn-color);
    transform: translateX(-110%);
    transition: 0.5s ease-in-out;
    @media screen and (max-width: 620px) {
      padding: 15px 15px 0 32px;
    }
    @media screen and (max-width: 440px) {
      padding: 10px;
    }
  }
  .technology-item:hover .technology-hover {
    transform: translateX(0);
  }
  .hover-text {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: $light;
  }
  .hover-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: $light;
  }
  .hover-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    background-size: 108%;
    background-position: center right;
    transform: translateX(-133px);
    filter: invert(68%) sepia(73%) saturate(3791%) hue-rotate(187deg) brightness(97%) contrast(95%);
    .light & {
      filter: invert(57%) sepia(94%) saturate(344%) hue-rotate(336deg) brightness(100%) contrast(98%);
    }
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
}

