.explore {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    align-items: flex-start;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $lg) {
      margin-bottom: 120px;
    }
  }
  .subtitle {
    display: inline-flex;
    margin: 0px auto 24px;
    &::before {
      background-color: $purple-points;
    }
    @media screen and (max-width: $lg) {
      margin: 0 0 14px 24px;
    }
  }
  .title {
    max-width: 800px;
    text-align: center;
    margin-bottom: 64px;
    @media screen and (max-width: $lg) {
      max-width: 275px;
      margin-bottom: 32px;
      font-size: 27px;
      line-height: 1.1;
      text-align: left;
    }
  }
  .video {
    width: calc(100vw - 128px);
    max-width: 1312px;
    height: calc(52vw - 66px);
    max-height: 680px;
    margin: 0 auto 48px;
    @media screen and (max-width: $lg) {
      width: calc(100vw - 48px);
      max-width: 410px;
      height: calc(56vw - 27px);
      max-height: 230px;
    }
    @media screen and (max-width: $sm) {
      width: calc(100vw - 48px);
      max-width: 327px;
      height: calc(56vw - 27px);
      max-height: 184px;
    }
  }
  .video-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    @media screen and (max-width: $lg) {
      border-radius: 8px;
    }
    & video {
      width: 100%;
      object-fit: cover;
    }
  }
  .video-container:hover .play {
    opacity: 0.8;
  }
  .player {
    width: calc(100vw - 128px);
    max-width: 1312px;
    height: calc(52vw - 66px);
    max-height: 680px;
    background-image: url('../../public/img/video-poster.png');
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    @media screen and (max-width: $lg) {
      width: calc(100vw - 48px);
      max-width: 410px;
      height: calc(56vw - 27px);
      max-height: 230px;
      background-image: url('../../public/img/video-poster-mobile.png');
    }
    @media screen and (max-width: $sm) {
      width: calc(100vw - 48px);
      max-width: 327px;
      height: calc(56vw - 27px);
      max-height: 184px;
    }
    .light & {
      background-image: url('../../public/img/video-poster-light.png');
      @media screen and (max-width: $lg) {
        background-image: url('../../public/img/video-poster-light-mobile.png');
      }
    }
  }
  .explore-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: calc(100vw - 48px);
      max-width: 410px;
      margin: 0 auto;
    }
    @media screen and (max-width: $sm) {
      width: calc(100vw - 48px);
      max-width: 327px;
    }
  }
  .explore-quote {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 466px;
    @media screen and (max-width: $lg) {
      width: 100%;
      margin-bottom: 28px;
    }
  }
  .image-container {
    display: flex;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-right: 24px;
    @media screen and (max-width: $lg) {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
  }
}

