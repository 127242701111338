.arrow-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  font-family: 'Thicccboi', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--font-color);
  overflow: hidden;
  @media screen and (max-width: $lg) {
    font-size: 14px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -135%;
    height: 2px;
    width: 245%;
    background-image: linear-gradient(to right, transparent 0 5%, var(--btn-color) 5% 45%, transparent 45% 55%, var(--btn-color) 55% 95%, transparent 95% 100%);
  }
  &:hover::after {
    animation: line-move 0.35s ease-out forwards;
  }
  & .arrow .svg path {
    fill: var(--font-color);
  }
  &:hover .arrow {
    top: 24px;
    left: 24px;
    animation: btn-move 0.35s ease-out forwards;
  }
  .arrow-wrapper {
    position: relative;
    display: inline-flex;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    overflow: hidden;
    & .arrow {
      position: absolute;
      top: 0px;
      left: 0px;
      display: inline-flex;
      width: 16px;
      height: 16px;
      transition: 0.3s;
    }
  }

  @keyframes btn-move {
    0% {
      top: 0px;
      left: 0px;
      opacity: 1;
    }
    25% {
      top: -16px;
      left: 16px;
      opacity: 0;
    }
    50% {
      top: 16px;
      left: -16px;
      opacity: 0;
    }
    100% {
      top: 0px;
      left: 0px;
      opacity: 1;
    }
  }

  @keyframes line-move {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(50%);
    }
  }
}

