.what-we-do {
  position: relative;
  z-index: 5;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  min-height: 100vh;
  @media screen and (max-width: $lg) {
    min-height: calc(100vh - 445px);
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 212px);
    min-height: 532px;
    margin-top: 120px;
    padding-top: 120px;
    @media screen and (max-width: $lg) {
      height: calc(100vh - 495px);
      min-height: 340px;
      padding-top: 64px;
    }
  }
  .subtitle {
    display: inline-flex;
    margin: 0px auto 24px;
    &::before {
      background-color: $blue-points;
      background-image: url('../../public/img/honeycomb.png');
    }
  }

  .slogan {
    max-width: 500px;
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 50px;
    line-height: 1.1;
    text-align: center;
    @media screen and (max-width: $xxl) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media screen and (max-width: $xl) {
      font-size: 40px;
      line-height: 1.3;
    }
    @media screen and (max-width: $lg) {
      max-width: 100%;
      margin-bottom: 27px;
      font-weight: 400;
      font-size: 27px;
      line-height: 1.1;
    }
  }
}

