.platform-anim {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .svg-container {
    position: relative;
    width: 506px;
    height: 506px;
    padding: 0;
    @media screen and (max-width: 1200px) {
      width: 330px;
      height: 330px;
    }
    @media screen and (max-width: 860px) {
      width: 506px;
      height: 506px;
      margin: 0 auto;
    }
    @media screen and (max-width: 576px) {
      width: 330px;
      height: 330px;
    }
  }
  .svg-center {
    position: absolute;
    width: 105px;
    height: 120px;
    @media screen and (max-width: 1200px) {
      width: 68px;
      height: 78px;
    }
    @media screen and (max-width: 860px) {
      width: 105px;
      height: 120px;
    }
    @media screen and (max-width: 576px) {
      width: 68px;
      height: 78px;
    }
  }
  .svg-center path {
    fill: var(--btn-color);
  }
  #rightMotionPath {
    stroke: url('#paint0_linear_789_11305');
  }
  .light & #rightMotionPath {
    stroke: url('#paint0_linear_857_18659');
  }
  #leftMotionPath {
    stroke: url('#paint0_linear_789_11304');
  }
  .light & #leftMotionPath {
    stroke: url('#paint0_linear_857_18658');
  }
  .point {
    fill: var(--btn-color);
  }
  .rect {
    fill: var(--nav-btn-color);
  }
  .cloud3-num {
    fill: var(--nav-btn-hover);
  }
  .cloud3-border {
    fill: #2a3753;
  }
  .light & .cloud3-border {
    fill: #ebebeb;
  }
  .cloud3-segment {
    fill: #008c76;
  }
  .light & .cloud3-segment {
    fill: #4bcba7;
  }
  .cloud4-border {
    stroke: var(--bg-color);
  }
}

