.footer {
  position: relative;
  z-index: 5;
  background-color: var(--bg-color);
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    min-height: 215px;
    margin: 0 auto;
    padding: 32px 64px 0;
    @media screen and (max-width: $xl) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .footer-links {
        order: 0;
      }
      .social,
      .line,
      .copyright {
        order: 2;
      }
    }
    @media screen and (max-width: $lg) {
      padding: 30px 24px 0;
    }
  }
  .logo {
    display: flex;
    flex-shrink: 0;
    width: 140px;
    height: 34px;
    background-image: url('../../public/svg/footer-logo.svg');
    background-size: contain;
    @media screen and (max-width: $xl) {
      margin-bottom: 22px;
    }
    .light & {
      background-image: url('../../public/svg/footer-logo-light.svg');
    }
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    background-color: #99a4b1;
    opacity: 0.3;
    @media screen and (max-width: $xl) {
      margin: 30px 0 24px;
    }
  }

  .copyright {
    font-size: 16px;
    opacity: 0.7;
    margin-bottom: 50px;
    @media screen and (max-width: $lg) {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

