.scroll-slider {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 752px;
  min-height: 100vh;
  @media screen and (max-width: $lg) {
    display: none;
  }
  .slider-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    height: 512px;
  }
  .pagination {
    position: relative;
    width: 24px;
    height: 352px;
    @media screen and (max-width: $lg) {
      display: none;
    }
    .slide-num {
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }
    .track {
      height: 252px;
      width: 2px;
      margin: 32px auto 42px;
      background-color: rgba(255, 255, 255, 0.2);
      .light & {
        background-color: rgba(3, 24, 48, 0.2);
      }
    }
    .scale {
      width: 2px;
      transition: 0.5s;
      background-color: $blue-points;
      .light & {
        background-color: $orange-points;
      }
    }
  }
}

.scrollmagic {
  @media screen and (max-width: $lg) {
    display: none;
  }
}

