.trusted2 {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 64px 120px;
  background-color: var(--bg-color);
  @media screen and (max-width: $xxl) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: $lg) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 90px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 64px;
    right: 64px;
    top: 0px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    @media screen and (max-width: $lg) {
      display: none;
    }
    .light & {
      background-color: rgba(3, 24, 48, 0.2);
    }
    .pricing-page & {
      display: none;
    }
  }
  .subtitle-container {
    @media screen and (max-width: $xxl) {
      padding-left: 64px;
      padding-right: 64px;
    }
    @media screen and (max-width: $lg) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  h3.subtitle {
    display: inline-flex;
    margin: 40px auto 24px;
    text-align: center;
    &::before {
      background-color: $purple-points;
    }
    @media screen and (max-width: $lg) {
      margin: 10px auto 16px;
      text-align: center;
    }
  }
  .title {
    text-align: center;
  }
  .wrapper {
    width: calc(100% - 56px);
    margin: 0 auto;
    overflow-x: hidden;
    @media screen and (max-width: $xxl) {
      width: 100%;
    }
  }
  .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 2620px;
    @media screen and (max-width: $xxl) {
      justify-content: flex-start;
      gap: 103px;
      width: 2728px;
      padding-left: 103px;
      animation: logos 15s infinite linear;
    }
    .image-container {
      display: flex;
      flex-shrink: 0;
      .light & {
        filter: brightness(1.8) grayscale(0.8);
      }
    }
    @media screen and (max-width: $lg) {
      gap: 63px;
      width: 2248px;
      padding-left: 63px;
    }
  }
  @keyframes logos {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}

