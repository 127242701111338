.security-hero {
  position: sticky;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    min-height: auto;
  }
  & .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 70px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 34px;
      margin-bottom: 44px;
    }
  }
  .content {
    @media screen and (max-width: $lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
    }
  }
  .title {
    max-width: 740px;
    margin-bottom: 28px;
    padding-top: 10px;
    font-size: 62px;
    line-height: 1.3;
    @media screen and (max-width: $xxl) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media screen and (max-width: $xl) {
      font-size: 40px;
      line-height: 1.3;
    }
    @media screen and (max-width: $lg) {
      padding-top: 16px;
      margin-bottom: 22px;
      max-width: 420px;
      font-size: 32px;
      text-align: center;
    }
  }
  .design {
    width: 548px;
    height: 476px;
    background-image: url('../../public/img/security-grafic.png');
    background-size: contain;
    .light & {
      background-image: url('../../public/img/security-grafic-light.png');
    }
    @media screen and (max-width: $xl) {
      width: 327px;
      height: 261px;
    }
    @media screen and (max-width: $lg) {
      width: 548px;
      height: 476px;
      margin: 0 auto;
    }
    @media screen and (max-width: 576px) {
      width: 327px;
      height: 261px;
    }
  }
  .highlight {
    display: inline-block;
    padding-left: 15px;
    color: $blue;
    .light & {
      color: $orange;
    }
  }
  .text {
    max-width: 528px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.6;
    @media screen and (max-width: $lg) {
      font-size: 18px;
      text-align: center;
    }
  }
  .bg-line {
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
  .arrow-right {
    display: inline-block;
    width: 52px;
    height: 32px;
    margin: 0 5px 0 15px;
    background-image: url('../../public/svg/arrow-right.svg');
    background-size: contain;
    @media screen and (max-width: $lg) {
      width: 29px;
      height: 18px;
    }
    .light & {
      background-image: url('../../public/svg/arrow-right-light.svg');
    }
  }
}

