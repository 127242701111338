.values {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  .container {
    padding: 0 64px;
    @media screen and (max-width: $lg) {
      padding: 0px 24px 50px;
    }
  }
  .subtitle {
    margin-left: 20px;
    &::before {
      background-color: $purple-points;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
  }
  .title {
    max-width: 600px;
    margin-bottom: 64px;
    @media screen and (max-width: $lg) {
      margin-bottom: 28px;
    }
  }
  .text {
    @media screen and (max-width: $lg) {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
    }
  }
  .values-items {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 78px;
    column-gap: 140px;
    @media screen and (max-width: 1200px) {
      gap: 48px;
    }
    @media screen and (max-width: $lg) {
      justify-content: center;
      gap: 44px;
      max-width: 400px;
      margin: 0 auto;
    }
  }
  .values-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    padding-left: 92px;
    @media screen and (max-width: 1200px) {
      width: calc(50% - 24px);
    }
    @media screen and (max-width: $lg) {
      width: 100%;
      padding-left: 64px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $icons;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../../public/svg/values1.svg');
      .light & {
        background-color: $white;
        background-image: url('../../public/svg/values1-light.svg');
      }
      @media screen and (max-width: $lg) {
        width: 40px;
        height: 40px;
        background-size: 18px;
      }
    }
    &:nth-child(2)::before {
      background-image: url('../../public/svg/values2.svg');
      .light & {
        background-color: $white;
        background-image: url('../../public/svg/values2-light.svg');
      }
    }
    &:nth-child(3)::before {
      background-image: url('../../public/svg/values3.svg');
      .light & {
        background-color: $white;
        background-image: url('../../public/svg/values3-light.svg');
      }
    }
    &:nth-child(4)::before {
      background-image: url('../../public/svg/values4.svg');
      .light & {
        background-color: $white;
        background-image: url('../../public/svg/values4-light.svg');
      }
    }
  }
  .card-title {
    margin-bottom: 24px;
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
  }
  .text {
    @media screen and (max-width: $lg) {
    }
  }
}

