.quote {
  position: relative;
  z-index: 5;
  transform: translateZ(0);
  background-color: var(--bg-color);
  .container {
    padding: 120px 64px 0;
    .pricing-page & {
      padding-top: 0;
      @media screen and (max-width: $lg) {
        padding-top: 32px;
      }
    }
    @media screen and (max-width: $lg) {
      padding: 0 24px 0px;
    }
    .company-page & {
      padding-bottom: 120px;
      @media screen and (max-width: $lg) {
        padding-bottom: 80px;
      }
    }
  }
  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 40px;
    border-radius: 16px;
    background-color: var(--nav-btn-color);
    @media screen and (max-width: $lg) {
      flex-direction: column;
      padding: 24px;
    }
    .cloud {
      left: 49px;
      top: 49px;
      animation: circle 10s linear infinite;
      @media screen and (max-width: $lg) {
        left: 36px;
        top: 36px;
        animation: circle-mobile 10s linear infinite;
      }
    }
  }
  .image-anim {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    background-image: url('../../public/svg/quote-circle.svg');
    background-size: contain;
    @media screen and (max-width: $lg) {
      width: 80px;
      height: 80px;
    }
    .light & {
      background-image: url('../../public/svg/quote-circle-light.svg');
    }
  }
  .image-container {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    @media screen and (max-width: $lg) {
      width: 62px;
      height: 62px;
    }
  }
  .quote-text {
    position: relative;
    padding: 24px 72px 0px 40px;
    line-height: 1.4;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 28px;
      width: 24px;
      height: 24px;
      background-image: url('../../public/svg/quote-icon.svg');
      background-size: contain;
    }
    @media screen and (max-width: $lg) {
      padding: 56px 0 24px;
      text-align: center;
      border-right: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      &::before {
        content: '';
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
        width: 40px;
        height: 40px;
      }
    }
    .light & {
      border-color: rgba(3, 24, 48, 0.2);
    }
  }
  .quote-image {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 224px;
    height: 144px;
    margin-left: 40px;
    @media screen and (max-width: $lg) {
      height: 108px;
      margin: 8px auto 0;
    }
  }
  .cloud {
    position: absolute;
    z-index: 5;
    left: 84px;
    top: 84px;
    width: 8px;
    height: 8px;
    background-image: url('../../public/svg/goal-cloud.svg');
    background-size: contain;
    @media screen and (max-width: $lg) {
      left: 37px;
      top: 37px;
      width: 6px;
      height: 6px;
    }
    .light & {
      background-image: url('../../public/svg/goal-cloud-light.svg');
    }
  }
  @keyframes circle {
    from {
      transform: rotate(0deg) translateX(51px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(51px) rotate(-360deg);
    }
  }
  @keyframes circle-mobile {
    from {
      transform: rotate(0deg) translateX(39px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(39px) rotate(-360deg);
    }
  }
  .quote-name {
    font-size: 16px;
    line-height: 26px;
    & span {
      padding-left: 3px;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
    @media screen and (max-width: $lg) {
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      & span {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

