@import '_vars.scss';
@import 'fonts';
@import '_vars';

:root {
  scrollbar-width: thin;
  --bg-color: #031830;
  --font-color: #ffffff;
  --opacity-01: rgba(255, 255, 255, 0.1);
  --opacity-02: rgba(255, 255, 255, 0.2);
  --opacity-06: rgba(255, 255, 255, 0.6);
  --opacity-08: rgba(255, 255, 255, 0.8);
  --btn-color: #0180ff;
  --btn-hover-dark-color: #004993;
  --btn-hover-light-color: #0064c9;
  --nav-btn-color: #192743;
  --nav-btn-hover: #ffffff;
  --honeycomb: url('../public/img/honeycomb.png') top center/100% auto no-repeat, linear-gradient(to top, #031830, #062d54);
  --honeycomb-mobile: url('../public/img/honeycomb.png') top center/auto no-repeat, linear-gradient(to top, #031830, #062d54);
}

.light {
  --bg-color: #f4f4f4;
  --font-color: #031830;
  --opacity-01: rgba(3, 24, 48, 0.1);
  --opacity-02: rgba(3, 24, 48, 0.2);
  --opacity-06: rgba(3, 24, 48, 0.6);
  --opacity-08: rgba(3, 24, 48, 0.8);
  --btn-color: #fe7518;
  --btn-hover-dark-color: #ff4f00;
  --btn-hover-light-color: #ff5e15;
  --nav-btn-color: #ffffff;
  --nav-btn-hover: #192743;
  --honeycomb: url('../public/img/honeycomb-light.png') top center/100% auto no-repeat, linear-gradient(to top, #f4f4f4, #ffffff);
  --honeycomb-mobile: url('../public/img/honeycomb-light.png') top center/auto no-repeat, linear-gradient(to top, #f4f4f4, #ffffff);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
}

html {
  scrollbar-color: #0180ff #020e1c;
  scroll-margin-top: 72px;

  scrollbar-gutter: stable;

  --scroll-behavior: smooth!important;
  scroll-behavior: smooth!important;

  &.noscroll {
    overflow-y: hidden;
  }

  &.noscroll2 {
    overflow-y: hidden;
  }
}

html.light {
  scrollbar-color: #fe7518 #cccccc;
}

.light * {
  scrollbar-color: #fe7518 #cccccc;
}

body {
  min-width: 375px;
  font-family: 'OpenSans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: var(--font-color);
  background: var(--bg-color);
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 64px;
  @media screen and (max-width: $lg) {
    padding: 40px 24px;
  }
}

h2 {
  font-family: 'Thicccboi', sans-serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--font-color);
  @media screen and (max-width: $xxl) {
    font-size: 48px;
    line-height: 1.3;
  }
  @media screen and (max-width: $xl) {
    font-size: 40px;
  }
  @media screen and (max-width: $lg) {
    font-size: 27px;
  }
}

h3.subtitle {
  position: relative;
  margin-bottom: 24px;
  font-family: 'Thicccboi', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--opacity-06);

  &::before {
    content: '';
    position: absolute;
    left: -16px;
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: $blue-points;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 1.6;
    &::before {
      top: 5px;
    }
  }
  &.orange::before {
    background-color: $orange-points;
  }
  &.green::before {
    background-color: $green-points;
  }
  &.blue::before {
    background-color: $blue-points;
  }
  &.purple::before {
    background-color: $purple-points;
  }
}

h4 {
  margin-bottom: 16px;
  font-family: 'Thicccboi', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;
  color: var(--font-color);
  @media screen and (max-width: $xl) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
}

p {
  font-family: 'OpenSans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--opacity-08);
  @media screen and (max-width: $lg) {
    font-size: 14px;
    line-height: 1.6;
  }
}

button {
  font-family: 'Thicccboi', sans-serif;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: 'Thicccboi', sans-serif;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  background-color: var(--btn-color);
  cursor: pointer;
  overflow: hidden;
  @media screen and (max-width: $lg) {
    font-size: 14px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--btn-hover-light-color);
    border-radius: 68px;
    z-index: -2;
    animation: btn-out 0.35s ease-out forwards;
  }
  &:hover::after {
    animation: btn-in 0.35s ease-out forwards;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--btn-hover-dark-color);
    border-radius: 68px;
    z-index: -1;
    animation: btn-out 0.35s ease-out forwards;
  }
  &:hover::before {
    animation: btn-in 0.35s ease-out forwards;
    animation-delay: 0.15s;
  }
}

@keyframes btn-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes btn-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.book-btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 20px 20px 20px 28px;
  border-radius: 68px;
  @media screen and (max-width: $lg) {
    padding: 16px 18px;
    font-size: 14px;
  }
  &:hover .arrow {
    top: 28px;
    left: 28px;
    animation: btn-move 0.5s ease-out forwards;
  }
}

.arrow-wrapper {
  position: relative;
  display: inline-flex;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  overflow: hidden;
  & .arrow {
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    background-image: url('../public/svg/arrow-top-right.svg');
    background-size: 16px 16px;
    transition: 0.3s;
  }
}

@keyframes btn-move {
  0% {
    top: 0px;
    left: 0px;
    opacity: 1;
  }
  25% {
    top: -16px;
    left: 16px;
    opacity: 0;
  }
  50% {
    top: 16px;
    left: -16px;
    opacity: 0;
  }
  100% {
    top: 0px;
    left: 0px;
    opacity: 1;
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.arrow-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  font-family: 'Thicccboi', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  overflow: hidden;
  @media screen and (max-width: $lg) {
    font-size: 14px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -135%;
    height: 2px;
    width: 245%;
    background-image: linear-gradient(to right, transparent 0 5%, var(--btn-color) 5% 45%, transparent 45% 55%, var(--btn-color) 55% 95%, transparent 95% 100%);
  }
  &:hover::after {
    animation: line-move 0.35s ease-out forwards;
  }
  &:hover .arrow {
    top: 24px;
    left: 24px;
    animation: btn-move 0.35s ease-out forwards;
  }
  .light & {
    color: $dark;
  }
  .light & .arrow {
    background-image: url('../public/svg/arrow-top-right-light.svg');
  }
}

@keyframes line-move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%);
  }
}

.nav-link {
  position: relative;
  display: inline-block;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  color: var(--opacity-06);
  transition: 0.3s;
  user-select: none;
  &:hover {
    color: var(--font-color);
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8px;
    width: 0;
    height: 1px;
    background-color: $white;
    transition: 0.3s;
  }
  &:hover::after {
    width: calc(100% - 15px);
  }
  &.active::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8px;
    width: calc(100% - 15px);
    height: 2px;
    background-color: $white;
    transition: 0.3s;
  }
  .light & {
    &.active::before {
      background-color: rgba(3, 24, 48, 0.6);
    }
  }
  .light &::after {
    background-color: $dark;
  }
}

.dropdown-arrow {
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-image: url('../public/svg/arrow-down.svg');
  background-size: 16px 16px;
  transition: 0.3s;
  &.open {
    transform: rotate(-180deg);
  }
  .light & {
    background-image: url('../public/svg/arrow-down-light.svg');
  }
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container * {
  position: unset !important;
}

.image {
  object-fit: cover;
  object-position: center center;
  width: 100% !important;
  position: relative !important;
  height: 100% !important;
}

.slider-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 132px;
  height: 56px;
  @media screen and (max-width: $lg) {
    width: 116px;
    height: 48px;
  }
}

.nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: $dark-grey;
  background-size: auto;
  transition: 0.3s;
  .light & {
    background-color: $light-grey;
  }
  &:hover {
    background-color: $dark-grey;
    .light & {
      background-color: $dark-grey;
    }
  }
  @media screen and (max-width: $lg) {
    width: 48px;
    height: 48px;
  }
}

.list-item {
  position: relative;
  padding-left: 34px;
  padding-bottom: 24px;
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: var(--opacity-08);
  &:last-child {
    padding-bottom: 12px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    width: 18px;
    height: 18px;
    background-image: url('../public/svg/list.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media screen and (max-width: $lg) {
    font-weight: 400;
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: 1200;
    stroke-width: 1;
  }
  100% {
    stroke-dashoffset: 1;
    stroke-width: 2;
  }
}

.honeycomb {
  background: var(--honeycomb);
  border-radius: 60px 60px 0 0;
  @media screen and (max-width: 1440px) {
    background: var(--honeycomb-mobile);
  }
  @media screen and (max-width: $lg) {
    border-radius: 32px 32px 0 0;
  }
}

::-webkit-scrollbar {
  width: 6px;
  background: $dark;
}

::-webkit-scrollbar-thumb {
  background: $blue;
  -webkit-border-radius: 3px;
}

.light::-webkit-scrollbar {
  width: 6px;
  background: $light;
}

.light::-webkit-scrollbar-thumb {
  background: $orange;
  -webkit-border-radius: 3px;
}

.light ::-webkit-scrollbar {
  width: 6px;
  background: $light;
}

.light ::-webkit-scrollbar-thumb {
  background: $orange;
  -webkit-border-radius: 3px;
}

@import '../components/Header/Header';
@import '../components/Header/Navigation/Navigation';
@import '../components/Header/Dropdown/Dropdown';
@import '../components/Footer/Footer';
@import '../components/Footer/FooterNavigation/FooterNavigation';
@import '../components/Footer/Social/Social';
@import '../components/HomeHero/HomeHero';
@import '../components/HomeHero/HomeHeroAnim/HomeHeroAnim';
@import '../components/Trusted/Trusted';
@import '../components/Trusted2/Trusted2';
@import '../components/Integrations/Integrations';
@import '../components/IntegrationsHoney/IntegrationsHoney';
@import '../components/WhatWeDo/WhatWeDo';
@import '../components/Security/Security';
@import '../components/Security/SecurityItem/SecurityItem';
@import '../components/Explore/Explore';
@import '../components/Customer/Customer';
@import '../components/Customer/CustomerItem/CustomerItem';
@import '../components/Pricing/Pricing';
@import '../components/Pricing/PricingItem/PricingItem';
@import '../components/ContactsHero/ContactsHero';
@import '../components/Form/Form';
@import '../components/Modal/Modal';
@import '../components/GetTouch/GetTouch';
@import '../components/GetTouch/GetTouchItem/GetTouchItem';
@import '../components/PartnersHero/PartnersHero';
@import '../components/ServicesHero/ServicesHero';
@import '../components/Benefit/Benefit';
@import '../components/Benefit/BenefitItem/BenefitItem';
@import '../components/ServiceProvider/ServiceProvider';
@import '../components/ServiceProvider/ServiceProviderItem/ServiceProviderItem';
@import '../components/CompanyHero/CompanyHero';
@import '../components/Goal/Goal';
@import '../components/Numbers/Numbers';
@import '../components/Numbers/NumberItem/NumberItem';
@import '../components/Leaders/Leaders';
@import '../components/Leaders/LeadersItem/LeadersItem';
@import '../components/Values/Values';
@import '../components/Quote/Quote';
@import '../components/Goal/GoalAnim/GoalAnim';
@import '../components/PlatformHero/PlatformHero';
@import '../components/PlatformHero/PlatformAnim/PlatformAnim';
@import '../components/OperationsHero/OperationsHero';
@import '../components/SecurityHero/SecurityHero';
@import '../components/ObservabilityHero/ObservabilityHero';
@import '../components/Slider/Slider';
@import '../components/Slider/SliderItem/SliderItem';
@import '../components/Technology/Technology';
@import '../components/SliderMobile/SliderMobile';
@import '../components/SliderMobile/SliderMobileItem/SliderMobileItem';
@import '../components/Double/Double';
@import '../components/ComparePlans/ComparePlans';
@import '../components/buttons/ArrowLink/ArrowLink';
@import '../components/buttons/BookBtn/BookBtn';
@import '../components/buttons/SwiperButtonNext/SwiperButtonNext';
@import '../components/buttons/SwiperButtonPrev/SwiperButtonPrev';
@import '../components/SwiperNav/SwiperNav';
@import '../components/Dashboards/Dashboards';
@import '../components/DashboardPlatform/DashboardPlatform';
@import '../components/Error/Error';
@import '../components/PrivacyPolicy/PrivacyPolicy';
@import '../components/Blog/Blog';
@import 'styles/blog.scss';
