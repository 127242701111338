.modal {
  position: fixed;
  z-index: 8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(3, 24, 48, 0.75);
  backdrop-filter: blur(24px);
  .light & {
    background: rgba(96, 96, 96, 0.75);
  }
  &.visible {
    display: block;
  }
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-60%);
  width: 489px;
  height: 457px;
  padding: 80px;
  background: linear-gradient(0deg, #192743, #192743), linear-gradient(136.53deg, #152b4b -8.93%, #042b57 114.73%);
  border-radius: 24px;
  color: $white;
  @media screen and (max-width: $lg) {
    width: 327px;
    height: 432px;
    padding: 80px 24px;
  }
  .light & {
    background: #eff0f5;
    box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.14);
    color: $dark;
  }
}
.icon {
  width: 64px;
  height: 64px;
  margin: 0 auto 24px;
  background-size: contain;
  background-image: url('../../public/svg/submit.svg');
  .light & {
    background-image: url('../../public/svg/submit-light.svg');
  }
}
.modal-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 16px;
  @media screen and (max-width: $lg) {
    font-size: 27px;
    line-height: 1.1;
  }
}
.modal-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: $lg) {
    font-size: 16px;
  }
}

