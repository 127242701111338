.nav {
  .nav-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 545px;
    padding-left: 20px;
    @media screen and (max-width: $xl) {
      width: calc(100vw - 310px);
      padding-left: 0;
    }
    @media screen and (max-width: $lg) {
      display: none;
      padding-left: 0;
    }
  }
  .navItem {
    list-style: none;
  }
  .center-btn {
    display: flex;
    justify-content: center;
  }
  .book-btn {
    display: none;
  }
}

.open .navigation-container {
  height: 100vh;
}

.open .nav {
  overflow-y: auto;
  position: fixed;
  top: 72px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: var(--bg-color);

  @media screen and (max-width: $lg) {
    top: 48px;
  }

  .nav-list {
    position: absolute;
    z-index: 6;
    right: 0;
    display: block;
    width: 100%;
    padding-top: 14px;
    background-color: $dark;

    @media screen and (max-width: $lg) {
      padding-top: 0px;

      .dropdownBody.open {
        padding-top: 0px;

        .dropdown-columns {
          margin-top: -10px;

          .dropdown-column {
            margin-bottom: 0px;

            .dropdown-inner-column {
              li:first-child {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .nav-link {
    position: relative;
    display: block;
    width: 100%;
    padding: 24px 24px;
    font-size: 18px;
    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 48px);
      left: 24px;
      bottom: 0;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    &.active {
      background-color: $blue;
      &::before {
        display: none;
      }
    }
  }
  .book-btn {
    display: inline-flex;
    padding: 14px 16px;
    margin: 100px 0 48px;
  }
}

.light .open .nav {
  .nav-list {
    background-color: $light;
  }
  .nav-link {
    &::after {
      background-color: rgba(3, 24, 48, 0.1);
    }
    &.active {
      background-color: $orange;
      color: $white;
      &::before {
        display: none;
      }
    }
  }
}

