.integrations {
  position: relative;
  z-index: 5;
  background-color: var(--bg-color);
  transform: translateZ(0);
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 64px 0;
    @media screen and (max-width: $lg) {
      padding: 80px 24px 0;
    }
  }
  .subtitle {
    display: flex;
    margin: 0px auto 24px;
    @media screen and (max-width: $lg) {
      margin: 0px auto 16px;
    }
    &::before {
      background-color: $orange-points;
    }
  }
  .title {
    max-width: 560px;
    margin-bottom: 64px;
    text-align: center;
    @media screen and (max-width: $lg) {
      margin-bottom: 40px;
    }
  }
  .wrapper {
    margin-bottom: 12px;
    overflow-x: hidden;
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2px);
  }
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 144px;
    border-bottom: 1px solid var(--opacity-01);
    border-right: 1px solid var(--opacity-01);
    &:nth-last-child(-n + 5) {
      border-bottom: 0;
    }
    @media screen and (max-width: $xxl) {
      width: 25%;
      height: 175px;
      &:nth-last-child(5) {
        border-bottom: 1px solid var(--opacity-01);
      }
    }
    @media screen and (max-width: $lg) {
      width: 50%;
      height: 150px;
      border-bottom: 1px solid var(--opacity-01);
      &:nth-last-child(4),
      &:nth-last-child(3) {
        border-bottom: 1px solid var(--opacity-01);
      }
    }
  }
  .image-container {
    width: 55px;
    height: 55px;
  }
  .company {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
  }
  .image {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .arrow-link {
    display: none;
    @media screen and (max-width: $lg) {
      display: block;
    }
  }
}

