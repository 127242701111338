.goal-anim {
  position: relative;
  width: 496px;
  height: 527px;
  background-image: url('../../../public/svg/goal-grafic.svg');
  @media screen and (max-width: 1200px) {
    width: 325px;
    height: 325px;
    background-size: contain;
  }
  .light & {
    background-image: url('../../../public/svg/goal-grafic-light.svg');
  }
  .cloud {
    position: absolute;
    left: 245px;
    top: 259px;
    width: 8px;
    height: 8px;
    background-image: url('../../../public/svg/goal-cloud.svg');
    @media screen and (max-width: 1200px) {
      left: 161px;
      top: 160px;
      width: 4px;
      height: 4px;
      background-size: contain;
    }
    .light & {
      background-image: url('../../../public/svg/goal-cloud-light.svg');
    }
  }
  .cloud1 {
    animation: circle1 16s linear infinite;
  }
  .cloud2 {
    animation: circle2 25s linear infinite;
  }
  .cloud3 {
    animation: circle2 25s linear infinite;
    animation-delay: -10s;
  }
  .cloud4 {
    animation: circle3 35s linear infinite;
  }
  .cloud5 {
    animation: circle3 35s linear infinite;
    animation-delay: -10s;
  }
  .goal-cloud {
    position: absolute;
    left: 222px;
    top: 236px;
    width: 54px;
    height: 54px;
    @media screen and (max-width: 1200px) {
      left: 148px;
      top: 147px;
      width: 30px;
      height: 30px;
      background-size: contain;
    }
  }
  .goal-cloud1 {
    animation: circle1 16s linear infinite;
    animation-delay: -4s;
    background-image: url('../../../public/svg/goal-cloud1.svg');
    .light & {
      background-image: url('../../../public/svg/goal-cloud1-light.svg');
    }
  }
  .goal-cloud2 {
    animation: circle2 25s linear infinite;
    animation-delay: -5s;
    background-image: url('../../../public/svg/goal-cloud2.svg');
    .light & {
      background-image: url('../../../public/svg/goal-cloud2-light.svg');
    }
  }
  .goal-cloud3 {
    animation: circle2 25s linear infinite;
    animation-delay: -15s;
    background-image: url('../../../public/svg/goal-cloud3.svg');
    .light & {
      background-image: url('../../../public/svg/goal-cloud3-light.svg');
    }
  }
  .goal-cloud4 {
    animation: circle3 35s linear infinite;
    animation-delay: -6s;
    background-image: url('../../../public/svg/goal-cloud4.svg');
    .light & {
      background-image: url('../../../public/svg/goal-cloud4-light.svg');
    }
  }
  .goal-cloud5 {
    animation: circle3 35s linear infinite;
    animation-delay: -16s;
    background-image: url('../../../public/svg/goal-cloud5.svg');
    .light & {
      background-image: url('../../../public/svg/goal-cloud5-light.svg');
    }
  }
  @keyframes circle1 {
    from {
      transform: rotate(0deg) translateX(105px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(105px) rotate(-360deg);
    }
  }
  @keyframes circle2 {
    from {
      transform: rotate(0deg) translateX(188px) rotate(0deg);
    }
    to {
      transform: rotate(-360deg) translateX(188px) rotate(360deg);
    }
  }
  @keyframes circle3 {
    from {
      transform: rotate(0deg) translateX(245px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(245px) rotate(-360deg);
    }
  }
}

.goal-anim {
  @media screen and (max-width: 1200px) {
    @keyframes circle1 {
      from {
        transform: rotate(0deg) translateX(69px) rotate(0deg);
      }
      to {
        transform: rotate(360deg) translateX(69px) rotate(-360deg);
      }
    }
    @keyframes circle2 {
      from {
        transform: rotate(0deg) translateX(125px) rotate(0deg);
      }
      to {
        transform: rotate(-360deg) translateX(125px) rotate(360deg);
      }
    }
    @keyframes circle3 {
      from {
        transform: rotate(0deg) translateX(163px) rotate(0deg);
      }
      to {
        transform: rotate(360deg) translateX(163px) rotate(-360deg);
      }
    }
  }
}

