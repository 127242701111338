.partners-hero {
  position: sticky;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    min-height: auto;
  }
  & .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 671px;
    padding: 124px 64px;
    text-align: center;
    @media screen and (max-width: $lg) {
      height: 773px;
      padding: 160px 24px 60px;
    }
  }
  .title {
    max-width: 780px;
    margin-bottom: 28px;
    font-size: 48px;
    line-height: 1.3;
    text-align: center;
    @media screen and (max-width: $xxl) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media screen and (max-width: $xl) {
      font-size: 40px;
      line-height: 1.3;
    }
    @media screen and (max-width: $lg) {
      padding-top: 16px;
      margin-bottom: 24px;
      max-width: 260px;
      font-size: 32px;
    }
  }
  .arrow-right {
    display: inline-block;
    width: 52px;
    height: 32px;
    margin: 0 15px;
    background-image: url('../../public/svg/arrow-right.svg');
    background-size: contain;
    @media screen and (max-width: $lg) {
      width: 29px;
      height: 18px;
    }
    .light & {
      background-image: url('../../public/svg/arrow-right-light.svg');
    }
  }
  .highlight {
    display: inline-block;
    color: $blue;
    .light & {
      color: $orange;
    }
  }
  .text {
    max-width: 782px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.6;
    text-align: center;
    &.text-desktop {
      display: block;
    }
    &.text-mobile {
      display: none;
    }
    @media screen and (max-width: $lg) {
      font-size: 18px;
      &.text-desktop {
        display: none;
      }
      &.text-mobile {
        display: block;
        max-width: 350px;
      }
    }
  }
  .logo {
    position: absolute;
    top: calc(50% - 185px);
    left: 182px;
    width: 96px;
    height: 96px;
    //background-image: url("../../public/img/partnersHeroLogo.png");
    background-size: contain;
    @media screen and (max-width: 1280px) {
      left: 10%;
    }
    @media screen and (max-width: 1124px) {
      display: none;
    }
    @media screen and (max-width: $lg) {
      display: block;
      top: calc(50% - 308px);
      left: calc(50% - 105px);
      width: 50px;
      height: 50px;
    }
  }
  .data {
    position: absolute;
    top: calc(50% + 80px);
    right: 115px;
    width: 134px;
    height: 141px;
    //background-image: url("../../public/img/partners-hero-data.png");
    background-size: contain;
    .light & {
      //background-image: url("../../public/img/partners-hero-data-light.png");
    }
    @media screen and (max-width: $lg) {
      top: calc(50% + 160px);
      left: calc(50% + 45px);
      width: 104px;
      height: 109px;
    }
  }
  .bg-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    max-width: 100vw;
    overflow: hidden;
    @media screen and (max-width: $lg) {
      overflow: hidden;
      opacity: 0;
    }
  }
  .bg-line-mobile {
    display: none;
    @media screen and (max-width: $lg) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  .book-btn {
    z-index: 5;
  }
}

