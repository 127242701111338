.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: 'Thicccboi', sans-serif;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  background-color: var(--btn-color);
  cursor: pointer;
  overflow: hidden;
  @media screen and (max-width: $lg) {
    font-size: 14px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--btn-hover-light-color);
    border-radius: 68px;
    z-index: -2;
    animation: btn-out 0.35s ease-out forwards;
  }
  &:hover::after {
    animation: btn-in 0.35s ease-out forwards;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--btn-hover-dark-color);
    border-radius: 68px;
    z-index: -1;
    animation: btn-out 0.35s ease-out forwards;
  }
  &:hover::before {
    animation: btn-in 0.35s ease-out forwards;
    animation-delay: 0.15s;
  }
}

.book-btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 20px 20px 20px 28px;
  border-radius: 68px;
  @media screen and (max-width: $lg) {
    padding: 16px 18px;
    font-size: 14px;
  }
  &:hover .arrow {
    top: 28px;
    left: 28px;
    animation: btn-move 0.5s ease-out forwards;
  }
}

.arrow-wrapper {
  position: relative;
  display: inline-flex;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  overflow: hidden;
  & .arrow {
    position: absolute;
    top: 0px;
    left: 0px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    transition: 0.3s;
    & svg {
      fill: $white;
    }
  }
}

@keyframes btn-move {
  0% {
    top: 0px;
    left: 0px;
    opacity: 1;
  }
  25% {
    top: -16px;
    left: 16px;
    opacity: 0;
  }
  50% {
    top: 16px;
    left: -16px;
    opacity: 0;
  }
  100% {
    top: 0px;
    left: 0px;
    opacity: 1;
  }
}

@keyframes btn-in {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes btn-out {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

