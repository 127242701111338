@use 'sass:color';
@use "sass:meta";

.light {
  @include meta.load-css('node_modules/github-markdown-css/github-markdown-light.css')
}

.dark {
  @include meta.load-css('node_modules/github-markdown-css/github-markdown-dark.css')
}

.blog {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;

  * {
    scroll-margin-top: 72px;
  }

  .blog-heading {
    flex: 0;
    align-self: center;
    font-size: 40px;
    font-weight: bold;
  }

  .blog-separator {
    opacity: 40%;
    margin-bottom: 0px;
  }

  .blog-content {
    margin: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1440px;

    @media screen and (max-width: 768px) {
      margin: 10px 0px;
    }

    .blog-sidebar {
      margin-right: 10px;
      flex: 0;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        display: none;
      }

      .blog-sidebar-heading {
        align-self: center;
        white-space: nowrap;
        // text-decoration: underline;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .blog-sidebar-items {
        display: flex;
        flex-direction: column;

        .blog-sidebar-item {
          list-style-type: none;
          padding: 2px;
        }
      }
    }

    .blog-posts {
      flex: 1;
      width: 100%;
      min-width: 100px;

      // https://www.trysmudford.com/blog/fade-out-siblings-css-trick/
      &:hover > *,
      &:focus-within > * {
        opacity: 0.4;
        transition: 0.3s ease-in-out;
        transition-property: opacity;
      }

      &:hover > :hover,
      &:focus-within > :focus {
        opacity: 1;
        transition: 0.3s ease-in-out;
        transition-property: opacity;
      }

    }
  }

  .blog-post-container {
    position: relative;
    flex: 1;
    width: 100%
  }

  .blog-post {
    background: var(--bg-color);

    & ul {
      margin-left: 20px;
    }

    & ol {
      margin-left: 26px;
    }

    & table {
      width: unset;
      white-space: nowrap;
      max-width: 100%;
      @media (max-width: 1080px) {
        width: 650px;
      }
      @media (max-width: 700px) {
        width: 340px;
      }
    }

    margin-bottom: 10px;
    border-radius: 6px;
    padding: 2px 6px;
  }

  .blog-post-summary {
    min-height: 50px;
    max-height: 400px;
    overflow: hidden;

    &.markdown-body {
      max-width: 800px;
      margin: 0 auto;
    }

    background-color: inherit;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);

    &::after {
      position: absolute;
      content: '';
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      opacity: 1;
      -webkit-mask-image: linear-gradient(to bottom,  rgba(0,0,0,0) 0px, rgba(0,0,0,0) 100px, rgba(0,0,0,0.5) 275px, rgba(0,0,0,1) 400px);
      background: var(--bg-color);
      pointer-events: none;
      transition: 0.3s ease-in-out;
      transition-property: opacity;
    }

    &:hover {
      & > :first-child {
        color: var(--btn-hover-dark-color);
        // transition: 0.3s;
      }

      &::after {
        opacity: 0;
      }
    }
  }

  .blog-tags {
    position: relative;
    padding-left: 4px;
    float: right;
    top: 12px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .blog-tag {
    border-radius: 6px;
    padding: 0px 4px;
    border: 1px solid;
    opacity: 80%;
    user-select: none;
    font-size: 0.9rem;
    margin: 2px;

    &:hover {
      background: var(--btn-hover-dark-color);
      cursor: pointer;

      .light & {
        &:hover {
          background: var(--btn-hover-light-color);
        }
      }
    }

    background: linear-gradient(180deg, #062d54 0%, rgba(3, 24, 48, 0) 136%), #192743;
    box-shadow: 0px 0px 80px rgba(3, 24, 48, 0.8);

    .light & {
      background: linear-gradient(180deg, #ffffff -4%, #f4f4f4 95%);
      box-shadow: 0px 0px 120px rgba(3, 24, 48, 0.1);
    }
  }

  .blog-post-view {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1080px;
    padding: 80px 10px;
    @media (max-width: 768px) {
      padding: 10px 10px;
    }
  }

  hr {
    opacity: 0.5;
    margin-bottom: 20px;
  }

  .blog-hoverable-toc {
    position: fixed;
    transition: 0.3s;
    top: 50%;
    transform: translate(0%, -50%);
    left: -320px;
    padding: 20px 40px;
    z-index: 10;

    &:hover {
      left: 0px;
    }
  }

  .blog-sticky-toc {
    position: sticky;
    top: 72px;
    height: fit-content;
    padding: 2px 6px;
    border-radius: 8px;
  }

  .blog-toc {
    background: linear-gradient(180deg, #062d54 0%, rgba(3, 24, 48, 0) 136%), #192743;
    box-shadow: 0px 0px 80px rgba(3, 24, 48, 0.8);

    .light & {
      background: linear-gradient(180deg, #ffffff -4%, #f4f4f4 95%);
      box-shadow: 0px 0px 120px rgba(3, 24, 48, 0.1);
    }

    > ul {
      padding-left: 0px !important;
    }

    & ul {
      padding-left: 20px;
      margin-left: -10px;
      list-style: none;
      margin-block-start: 0px;
      //padding-inline-start: 20px;
    }

    & li {
      list-style: none;
    }

    & .blog-toc-link {
      padding: 2px 10px;
      white-space: nowrap;
      width: 280px;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }
}

.blog.feed {
   .blog-posts {
      margin: 0px 30px;

      @media screen and (max-width: 768px) {
        margin: 2px;
      }
   }
 }
