.company-hero {
  position: sticky;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    min-height: auto;
  }
  & .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 664px;
    padding: 188px 64px 120px;
    text-align: center;
    @media screen and (max-width: $lg) {
      height: 800px;
      padding: 140px 24px 80px;
    }
  }
  .title {
    max-width: 1010px;
    margin-bottom: 28px;
    font-size: 62px;
    line-height: 1.3;
    text-align: center;
    @media screen and (max-width: $xxl) {
      font-size: 50px;
      line-height: 1.3;
    }
    @media screen and (max-width: $xl) {
      font-size: 40px;
      line-height: 1.3;
    }
    @media screen and (max-width: $lg) {
      max-width: 430px;
      padding-top: 16px;
      margin-bottom: 24px;
      font-size: 32px;
    }
  }
  .highlight {
    display: inline-block;
    padding-left: 18px;
    color: var(--btn-color);
  }
  .text {
    max-width: 660px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.6;
    text-align: center;
    @media screen and (max-width: $lg) {
      max-width: 430px;
      font-size: 18px;
    }
  }
  .arrow-wrapper {
    margin-left: 32px;
  }
  .bg-icon-top {
    position: absolute;
    width: 287px;
    height: 255px;
    top: 56px;
    left: 50%;
    transform: translateX(-64%);
    background-size: contain;
    background-image: url('../../public/svg/company-top.svg');
    @media screen and (max-width: $lg) {
      top: 32px;
    }
    .light & {
      background-image: url('../../public/svg/company-top-light.svg');
    }
  }
  .bg-icon-left {
    position: absolute;
    width: 177px;
    height: 304px;
    top: 50%;
    left: 64px;
    transform: translateY(-42%);
    background-size: contain;
    background-image: url('../../public/svg/company-left.svg');
    @media screen and (max-width: 1120px) {
      left: 24px;
      transform: translateY(-22%);
    }
    @media screen and (max-width: $lg) {
      display: none;
    }
    .light & {
      background-image: url('../../public/svg/company-left-light.svg');
    }
  }
  .bg-icon-right {
    position: absolute;
    width: 265px;
    height: 368px;
    top: 50%;
    right: 64px;
    transform: translateY(-23%);
    background-size: contain;
    background-image: url('../../public/svg/company-right.svg');
    @media screen and (max-width: 1120px) {
      right: 24px;
      transform: translateY(-12%);
    }
    @media screen and (max-width: $lg) {
      display: none;
    }
    .light & {
      background-image: url('../../public/svg/company-right-light.svg');
    }
  }
  .bg-icon-mobile {
    display: none;
    position: absolute;
    width: 308px;
    height: 152px;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    background-image: url('../../public/svg/company-mobile.svg');
    @media screen and (max-width: $lg) {
      display: block;
    }
    .light & {
      background-image: url('../../public/svg/company-mobile-light.svg');
    }
  }
}

