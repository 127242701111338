.dashboards {
  position: relative;
  z-index: 5;
  background-color: var(--bg-color);
  .container {
    padding: 120px 64px 0;
    @media screen and (max-width: $lg) {
      padding: 24px 24px 0;
    }
  }
  .inner-container {
    padding: 72px;
    border-radius: 16px;
    background-color: var(--nav-btn-color);
    @media screen and (max-width: $lg) {
      flex-direction: column;
      padding: 22px 24px 40px;
    }
  }
  .subtitle {
    margin-left: 20px;
    &::before {
      background-color: $purple-points;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 14px;
      margin-top: 20px;
    }
  }
  .title {
    max-width: 100%;
    margin-bottom: 54px;
    line-height: 1.1;
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    column-gap: 80px;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      margin-bottom: 32px;
    }
  }
  .list-item {
    width: calc(50% - 40px);
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    color: var(--font-color);
    @media screen and (max-width: $lg) {
      width: 100%;
      font-size: 14px;
      color: var(--opacity-08);
      margin-bottom: 0;
    }
  }
  .img-container {
    width: 1168px;
    height: 552px;
    border: 1px solid var(--opacity-02);
    border-radius: 16px;
    @media screen and (max-width: 1400px) {
      width: calc(100vw - 272px);
      height: calc(47vw - 128px);
    }
    @media screen and (max-width: $lg) {
      width: calc(100vw - 96px);
      height: calc(68vw - 65px);
    }
  }
  .img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 16px;
  }
}

