.numbers {
  .number {
    position: relative;
    padding: 0 48px;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 34px;
      width: 1px;
      height: 134px;
      background-color: rgba(255, 255, 255, 0.15);
      .light & {
        background-color: rgba(3, 24, 48, 0.15);
      }
    }
    &:last-child::after {
      display: none;
    }
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      padding: 28px 24px;
      &::after {
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        width: 100%;
        height: 1px;
      }
      &:last-child::after {
        display: block;
      }
    }
    @media screen and (max-width: $lg) {
      justify-content: center;
      text-align: center;
      &:last-child::after {
        display: none;
      }
    }
  }
  .number-title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $blue-points;
    .light & {
      color: $orange;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 12px;
      font-size: 12px;
    }
  }
  .number-count {
    font-weight: 500;
    font-size: 56px;
    line-height: 1.1;
    margin-bottom: 16px;
    color: var(--font-color);
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
      font-size: 27px;
    }
  }
  .number-text {
    max-width: 220px;
    font-weight: 400;
    color: var(--opacity-08);
  }
}

