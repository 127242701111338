.get-touch-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 336px;
  @media screen and (max-width: $lg) {
    margin-bottom: 72px;
  }
  @media screen and (max-width: $lg) {
    width: 327px;
  }
  .card-title {
    margin-bottom: 24px;
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
  }
  .text {
    margin-bottom: 24px;
    text-align: center;
    @media screen and (max-width: $lg) {
      max-width: 260px;
      margin-bottom: 20px;
    }
  }
}

