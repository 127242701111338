.footer-nav {
  .footer-nav-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 660px;
    @media screen and (max-width: $xxl) {
      width: 100%;
    }
    @media screen and (max-width: $xl) {
      width: calc(100vw - 128px);
      flex-wrap: wrap;
    }
    @media screen and (max-width: $lg) {
      width: calc(100vw - 48px);
    }
  }
  .footer-navItem {
    list-style: none;
    @media screen and (max-width: $xl) {
      width: 50%;
      padding: 7px 0;
    }
    @media screen and (max-width: $lg) {
      padding: 5px 0;
    }
  }
}

.footer-links {
  width: 246px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $xl) {
    width: calc(100vw - 128px);
    flex-wrap: wrap;
    margin-bottom: 26px;
  }
  @media screen and (max-width: $lg) {
    width: calc(100vw - 48px);
  }
  .footer-navItem {
    list-style: none;
    @media screen and (max-width: $xl) {
      width: 50%;
      padding: 7px 0;
    }
    @media screen and (max-width: $lg) {
      padding: 5px 0;
    }
  }
}

