.feed {
  position: relative;
  z-index: 5;
  background-color: var(--bg-color);
  padding: 40px 64px 0;
  @media (max-width: 700px) {
    padding: 0px;
  }
  width: 100%;
  max-width: 1280px;
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      display: flex;
      align-items: center;
    }
    margin: 0 auto;
    padding: 30px;
    @media screen and (max-width: $lg) {
      padding: 24px 24px 0;
    }
  }
  .design {
    width: 548px;
    height: 476px;
    background-image: url('../../public/img/operations-grafic.png');
    background-size: contain;
    .light & {
      background-image: url('../../public/img/operations-grafic-light.png');
    }
    @media screen and (max-width: $xl) {
      width: 327px;
      height: 261px;
    }
    @media screen and (max-width: $lg) {
      width: 548px;
      height: 476px;
      margin: 0 auto;
    }
    @media screen and (max-width: 576px) {
      width: 327px;
      height: 261px;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 100%;
    @media (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    padding: 20px;
    gap: 30px 30px;
  }
  .inner-container {
    position: relative;
    padding: 30px;
    border-width: 1px;
    border-radius: 16px;
    border-color: var(--nav-btn-color);
    height: 400px;
    background-color: var(--nav-btn-color);
    margin: 2px;
    .read-more {
      display: flex;
      width: 83%;
      justify-content: space-between;
      align-items: flex-end;
      position: absolute;
      bottom: 30px;
    }
    &:hover {
      border-color: var(--btn-color);
      border-width: 1px;
      border-style: solid;
      margin: 1px;
      cursor: pointer;
      .title {
        text-decoration: underline;
        text-decoration-color: var(--btn-color);
        text-decoration-thickness: 2pt;
      }
      .read-more {
        bottom: 28px;
      }
    }
    @media screen and (max-width: $lg) {
      flex-direction: column;
      padding: 22px 24px 40px;
    }
  }
  .post-tags {
    padding-bottom: 10px;
    padding-right: 0px;
  }
  .read-more-btn {
    padding: 40px 0px;
  }
  .subtitle {
    font-weight: 200;
    &::before {
      background-color: $purple-points;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 14px;
      margin-top: 20px;
    }
  }
  .summary {
    font-size: 10pt;
    font-weight: 100;
  }
  .subtitle-overline {
    position: relative;
    margin-left: 20px;
    margin-bottom: 24px;
    font-family: 'Thicccboi', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--opacity-06);
    &::before {
      content: '';
      position: absolute;
      left: -16px;
      top: 8px;
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: $blue-points;
    }
  
    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 1.6;
      &::before {
        top: 5px;
      }
    }
    &.orange::before {
      background-color: $orange-points;
    }
    &.green::before {
      background-color: $green-points;
    }
    &.blue::before {
      background-color: $blue-points;
    }
    &.purple::before {
      background-color: $purple-points;
    }  
  }

  .featured-title {
    font-family: OpenSans, sans-serif;
    max-width: 100%;
    margin: 10px 0px;
    padding: 10px 0px;
    overflow: hidden;
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  .title {
    max-width: 100%;
    margin: 20px 0px;
    line-height: 1.6rem;
    padding: 10px 0px;
    overflow: hidden;
    font-size: 1.5rem;
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  .fade {
    position: relative;
    height: 3.6rem; /* exactly three lines */
  }
  .fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.2rem;
    background: linear-gradient(to right, rgba(var(--nav-btn-color), 0), rgba(var(--nav-btn-color), 1) 50%);
  }
}