.header {
  position: fixed;
  top: 0;
  z-index: 6;
  width: 100vw;
  background-color: var(--bg-color);

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    min-width: 375px;
    margin: 0 auto;
    padding: 15px 64px;
    @media screen and (max-width: $lg) {
      padding: 8px;
    }
  }
  .logo {
    flex-shrink: 0;
    width: 152px;
    height: 37px;
    margin-right: 37px;
    background-image: url('../../public/svg/logo.svg');
    background-size: contain;
    @media screen and (max-width: $xxl) {
      margin-right: 0;
    }
    @media screen and (max-width: $xl) {
      width: 110px;
      height: 26px;
    }
    .light & {
      background-image: url('../../public/svg/logo-light.svg');
    }
  }
  .buttons-container {
    display: flex;
    align-items: center;

    > button {
      @media screen and (max-width: $lg) {
        width: 32px;
        height: 32px;
        margin-left: 6px;
      }
    }
  }
  .request-btn {
    flex-shrink: 0;
    margin-right: 16px;
    padding: 13px 19px;
    background-color: $blue;
    border-radius: 58px;
    font-size: 14px;
    @media screen and (max-width: $xl) {
      display: none;
    }
    .light & {
      background-color: $orange;
    }
  }
  .light-mode {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border: 1px solid var(--opacity-02);
    border-radius: 50%;
    background-image: url('../../public/svg/sun.svg');
    background-size: auto;
    transition: 0.3s;
    &:hover {
      border: 1px solid var(--opacity-06);
    }
    .light & {
      background-image: url('../../public/svg/moon.svg');
    }
  }
  .burger-btn {
    display: none;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    border: 1px solid var(--opacity-02);
    border-radius: 50%;
    background-image: url('../../public/svg/burger.svg');
    background-size: auto;
    transition: 0.3s;
    @media screen and (max-width: $lg) {
      display: block;
    }
    &:hover {
      border: 1px solid var(--opacity-06);
    }
    .light & {
      background-image: url('../../public/svg/burger-light.svg');
    }
  }
}

.header.open {
  z-index: 6;

  position: fixed;

  .container {
    height: 72px;
    @media screen and (max-width: $lg) {
      height: 48px;
    }
  }
  .burger-btn {
    background-image: url('../../public/svg/close.svg');
  }
}

.light .header.open {
  .burger-btn {
    background-image: url('../../public/svg/close-light.svg');
  }
}

