.security-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto 16px;
  transform: translateZ(0);
  width: 100%;
  &:first-child.open {
    margin-bottom: 48px;
  }
  &:last-child {
    @media screen and (max-width: $lg) {
      margin-bottom: 0;
    }
  }
  .content-slider {
    @media screen and (max-width: $lg) {
      align-items: flex-start;
      width: 327px;
      height: 222px;
    }
  }
  .image-slide {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 327px;
    height: 293px;
    margin-bottom: 32px;
    border-radius: 24px;
    overflow: hidden;
    background-image: linear-gradient(to top, #55d0f7, #57b8ff);
    @media screen and (max-width: $lg) {
      width: 327px;
      height: 293px;
    }
    .light & {
      background-image: linear-gradient(to top, #f3a64b, #fe7518);
    }
    .img {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 295px;
      height: 258px;
      background-size: contain;
      background-position: bottom center;
    }
  }
  .content-slide {
    min-height: 359px;
    margin-top: 12px;
    .subtitle {
      margin-left: 20px;
      margin-bottom: 24px;
      @media screen and (max-width: $lg) {
        display: none;
      }
      .light &::before {
        background-color: $purple-points;
      }
    }
    .title {
      margin-bottom: 32px;
      @media screen and (max-width: $lg) {
        max-width: 240px;
        margin-bottom: 16px;
        font-size: 27px;
        line-height: 1.1;
      }
    }
    .text {
      margin-bottom: 64px;
      @media screen and (max-width: $lg) {
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }
  .card-header {
    width: 100%;
    text-transform: uppercase;
    padding: 24px 0 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: none;
    @media screen and (max-width: $lg) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 327px;
    }
    .subtitle {
      margin-left: 24px;
      margin-bottom: 12px;
    }
  }
  &:nth-child(1) {
    & .card-header {
      border-top: 0;
    }
  }
  &:nth-child(2) {
    .card-header {
      .subtitle {
        &::before {
          background-color: $purple-points;
        }
      }
    }
  }
  &:nth-child(3) {
    .card-header {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .subtitle {
        &::before {
          background-color: $orange-points;
        }
      }
    }
  }
  .toggle-btn {
    width: 24px;
    height: 24px;
    background-image: url('../../../public/svg/plus.svg');
    background-size: auto;
    display: none;
    @media screen and (max-width: $lg) {
      display: flex;
    }
    .light & {
      background-image: url('../../../public/svg/plus-light.svg');
    }
  }
  .card-body {
    transition: 1s;
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
}

.security-item.open {
  @media screen and (max-width: $lg) {
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto 32px;
      width: calc(100vw - 48px);
    }
  }
  .toggle-btn {
    @media screen and (max-width: $lg) {
      background-image: url('../../../public/svg/minus.svg');
      .light & {
        background-image: url('../../../public/svg/minus-light.svg');
      }
    }
  }
  .subtitle {
    @media screen and (max-width: $lg) {
      margin-bottom: 24px;
    }
  }
  &:nth-child(3) {
    .card-header {
      padding-bottom: 0px;
      border-bottom: 0;
    }
  }
}

