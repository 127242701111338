.customer-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 528px;
  height: 343px;
  padding: 32px;
  border: 1px solid #132f4c;
  transform: translateZ(0);
  border-radius: 16px;
  .light & {
    border-color: #d6dbe1;
  }
  @media screen and (max-width: $lg) {
    height: 280px;
  }
  @media screen and (max-width: $sm) {
    width: 327px;
    height: 266px;
    padding: 24px;
  }
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    @media screen and (max-width: $sm) {
      font-size: 12px;
    }
  }
  .job {
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    opacity: 0.8;
    @media screen and (max-width: $sm) {
      font-size: 12px;
    }
  }
}

