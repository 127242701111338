.numbers {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color);
  @media screen and (max-width: $lg) {
    align-items: flex-start;
  }
  .container {
    display: flex;
    justify-content: space-between;
    padding: 0 64px 120px;
    @media screen and (max-width: $lg) {
      padding: 0 24px 80px;
    }
  }
  .inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 64px 30px 80px;
    border-radius: 16px;
    background-color: var(--nav-btn-color);
    @media screen and (max-width: $lg) {
      padding: 24px 24px 0;
    }
  }
  .subtitle {
    margin-left: 20px;
    margin-bottom: 24px;
    &::before {
      background-color: $purple-points;
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 16px;
    }
  }
  .title {
    max-width: 800px;
    text-align: center;
    margin-bottom: 64px;
    line-height: 1.1;
    @media screen and (max-width: $lg) {
      margin-bottom: 8px;
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto;
    }
  }
}

